import Http from "./Http";
import { getToken } from "../shared/ultils/token";

// Fetch countries with authentication
export const getCountries = (page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(`${process.env.REACT_APP_API_GET_COUNTRIES}?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};

// Fetch regions by country ID with authentication
export const getRegionsByCountryId = (countryId, page = 0) => {
  const token = getToken();
  return Http.get(
    `${process.env.REACT_APP_API_GET_REGIONS_BY_COUNTRY}?countryId=${countryId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// Fetch fields by region ID with authentication
export const getFieldsByRegionId = (regionId, page = 0) => {
  const token = getToken();
  return Http.get(
    `${process.env.REACT_APP_API_GET_FIELDS_BY_REGION}?regionId=${regionId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
// Fetch tree by field ID with authentication
export const getTreesByFieldId = (fieldId, page = 0) => {
  const token = getToken();
  return Http.get(
    `${process.env.REACT_APP_API_GET_TREES_BY_FIELD}?fieldId=${fieldId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
// Fetch tree details by tree ID with authentication
export const getTreeDetailsByTreeId = (treeId) => {
  const token = getToken();
  return Http.get(
    `${process.env.REACT_APP_API_GET_TREE_DETAILS}?treeId=${treeId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
