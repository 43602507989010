import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRobotTappingDetail } from "../services/robot";

const RobotTappingDetails = () => {
    const { tappingId } = useParams();
    const [robotDetail, setRobotDetail] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchRobotDetail = async () => {
        try {
          const response = await getRobotTappingDetail(tappingId);
          if (response.data.data) {
            setRobotDetail(response.data.data);
          } else {
            setRobotDetail(null);
          }
          console.log(response.data.data);
        } catch (error) {
          console.error("Error fetching drone detail:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchRobotDetail();
    }, [tappingId]);
  
    return (
      <div
        className="container mt-5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="col-md-10 col-lg-8">
          <h2 className="text-center mb-4" style={{ fontSize: "2rem" }}>
            Robot Tapping Detail
          </h2>
          {loading ? (
            <p className="text-center" style={{ fontSize: "1.25rem" }}>
              Loading...
            </p>
          ) : robotDetail ? (
            <div
              className="card shadow-lg hover-card"
              style={{
                padding: "2rem",
                fontSize: "1.5rem",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
              }}
            >
              <div className="card-body">
                <h5
                  className="card-title mb-4 text-center"
                  style={{ fontSize: "2rem" }}
                >
                  Robot Tapping ID: {robotDetail.id}
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Robot Id:</strong> {robotDetail.robotId}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Tree Id: </strong>
                      {robotDetail.treeId}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Direction: </strong> {robotDetail.direction}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Speed: </strong> {robotDetail.speed}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Quantity: </strong> {robotDetail.quantity}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Angle: </strong> {robotDetail.angle}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Depth: </strong> {robotDetail.depth}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Blade Status: </strong> {robotDetail.bladeStatus}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Tapping Status: </strong> {robotDetail.tappingStatus}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Wind Direction: </strong> {robotDetail.windDirection}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Wind Speed: </strong> {robotDetail.windSpeed}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Temperature: </strong> {robotDetail.temperature}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Rainfall: </strong> {robotDetail.rainfall}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-text mb-3">
                      <strong>Time: </strong> {robotDetail.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-center" style={{ fontSize: "1.25rem" }}>
              Robot Tapping detail not found.
            </p>
          )}
        </div>
      </div>
    );
  };
  
  export default RobotTappingDetails;
  