import React, { useState } from 'react';

const ForgetPassword = () => {
  

  
  return(
    <>
    <main id="content" role="main" className="main">
  <div className="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: 'url(assets/svg/components/abstract-bg-4.svg)'}}>
    {/* SVG Bottom Shape */}
    <figure className="position-absolute right-0 bottom-0 left-0">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
        <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    {/* End SVG Bottom Shape */}
  </div>
  {/* Content */}
  <div className="container py-5 py-sm-7">
    <a className="d-flex justify-content-center mb-5" href="index.html">
      <img className="z-index-2" src="assets\svg\logos\logo.svg" alt="Image Description" style={{width: '8rem'}} />
    </a>
    <div className="row justify-content-center">
      <div className="col-md-7 col-lg-5">
        {/* Card */}
        <div className="card card-lg mb-5">
          <div className="card-body">
            {/* Form */}
            <form className="js-validate">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-4">Forgot password?</h1>
                  <p>Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
                </div>
              </div>
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="resetPasswordSrEmail" tabIndex={0}>Your email</label>
                <input type="email" className="form-control form-control-lg" name="email" id="resetPasswordSrEmail" tabIndex={1} placeholder="Enter your email address" aria-label="Enter your email address" required data-msg="Please enter a valid email address." />
              </div>
              {/* End Form Group */}
              <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
              <div className="text-center mt-2">
                <a className="btn btn-link" href="authentication-signin-basic.html">
                  <i className="tio-chevron-left" /> Back to Sign in
                </a>
              </div>
            </form>
            {/* End Form */}
          </div>
        </div>
        {/* End Card */}
        {/* Footer */}
        <div className="text-center">
          <small className="text-cap mb-4">Trusted by the world's best teams</small>
          <div className="w-85 mx-auto">
            <div className="row justify-content-between">
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\gitlab-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\fitbit-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\flow-xo-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\layar-gray.svg" alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </div>
    </div>
  </div>
  {/* End Content */}
</main>

    </>
  )
};

export default ForgetPassword;