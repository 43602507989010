import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTreesByFieldId } from '../services/areas';

const RubberTreeList = () => {
  const { fieldId } = useParams();
  const [trees, setTrees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate(); // Add navigate hook

  useEffect(() => {
    if (fieldId) {
      const fetchTrees = async () => {
        try {
          const response = await getTreesByFieldId(fieldId, page);
          setTrees(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error('Error fetching trees:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchTrees();
    }
  }, [fieldId, page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleCardClick = (treeId) => {
    navigate(`/rubber-tree-details/${treeId}`); // Navigate to tree details page
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Rubber Trees</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="row">
            {trees.map(tree => (
              <div key={tree.treeId} className="col-lg-4 col-md-6 mb-4">
                <div
                  className="card shadow-sm h-100"
                  onClick={() => handleCardClick(tree.treeId)} // Add click handler
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Tree ID: {tree.treeId}</h5>
                    <p className="card-text">Location: {tree.location}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? 'active' : ''
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RubberTreeList;
