import React, { useState } from 'react';

const VerificationStep2 = () => {
  

  
  return(
    <>
    <main id="content" role="main" className="main">
  <div className="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: 'url(assets/svg/components/abstract-bg-4.svg)'}}>
    {/* SVG Bottom Shape */}
    <figure className="position-absolute right-0 bottom-0 left-0">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
        <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    {/* End SVG Bottom Shape */}
  </div>
  {/* Content */}
  <div className="container py-5 py-sm-7">
    <a className="d-flex justify-content-center mb-5" href="index.html">
      <img className="z-index-2" src="assets\svg\logos\logo.svg" alt="Image Description" style={{width: '8rem'}} />
    </a>
    <div className="row justify-content-center">
      <div className="col-md-7 col-lg-5">
        {/* Card */}
        <div className="card card-lg mb-5">
          <div className="card-body text-center">
            <div className="mb-4">
              <img className="avatar avatar-xxl avatar-4by3" src="assets\svg\illustrations\unlock.svg" alt="Image Description" />
            </div>
            <div className="mb-5">
              <h1 className="display-4">2-step Verification</h1>
              <p className="mb-0">We sent a verification code to your email.</p>
              <p>Enter the code from the email in the field below.</p>
            </div>
            <div className="row gx-2 gx-sm-3">
              <div className="col">
                {/* Form Group */}
                <div className="form-group">
                  <input type="text" className="js-masked-input form-control form-control-single-number" name="code1" id="twoStepVerificationSrCodeInput1" placeholder aria-label maxLength={1} autoComplete="off" autoCapitalize="off" spellCheck="false" autofocus data-hs-mask-options="{
                         &quot;template&quot;: &quot;0&quot;
                       }" />
                </div>
                {/* End Form Group */}
              </div>
              <div className="col">
                {/* Form Group */}
                <div className="form-group">
                  <input type="text" className="js-masked-input form-control form-control-single-number" name="code2" id="twoStepVerificationSrCodeInput2" placeholder aria-label maxLength={1} autoComplete="off" autoCapitalize="off" spellCheck="false" data-hs-mask-options="{
                         &quot;template&quot;: &quot;0&quot;
                       }" />
                </div>
                {/* End Form Group */}
              </div>
              <div className="col">
                {/* Form Group */}
                <div className="form-group">
                  <input type="text" className="js-masked-input form-control form-control-single-number" name="code3" id="twoStepVerificationSrCodeInput3" placeholder aria-label maxLength={1} autoComplete="off" autoCapitalize="off" spellCheck="false" data-hs-mask-options="{
                         &quot;template&quot;: &quot;0&quot;
                       }" />
                </div>
                {/* End Form Group */}
              </div>
              <div className="col">
                {/* Form Group */}
                <div className="form-group">
                  <input type="text" className="js-masked-input form-control form-control-single-number" name="code4" id="twoStepVerificationSrCodeInput4" placeholder aria-label maxLength={1} autoComplete="off" autoCapitalize="off" spellCheck="false" data-hs-mask-options="{
                         &quot;template&quot;: &quot;0&quot;
                       }" />
                </div>
                {/* End Form Group */}
              </div>
            </div>
            <button type="submit" className="btn btn-lg btn-block btn-primary">Verify my account</button>
            <div className="text-center mt-3">
              <p>Haven't received it? <a href="#">Resend a new code.</a></p>
            </div>
          </div>
        </div>
        {/* End Card */}
        {/* Footer */}
        <div className="text-center">
          <small className="text-cap mb-4">Trusted by the world's best teams</small>
          <div className="w-85 mx-auto">
            <div className="row justify-content-between">
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\gitlab-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\fitbit-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\flow-xo-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\layar-gray.svg" alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </div>
    </div>
  </div>
  {/* End Content */}
</main>

    </>
  )
};

export default VerificationStep2;