import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPlanByField } from "../services/plan";

const PlanList = () => {
  const { fieldId } = useParams();
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (fieldId) {
      const fetchPlans = async () => {
        try {
          const response = await getPlanByField(fieldId, page);
          setPlans(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching plans:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlans();
    }
  }, [fieldId, page]);

  const handlePlanClick = (planId) => {
    navigate(`/plan-detail/${planId}`);
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Plans</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="row">
            {plans.map((plan) => (
              <div
                key={plan.fieldId}
                className="col-lg-4 col-md-6 mb-4"
                onClick={() => handlePlanClick(plan.planId)}
                style={{ cursor: "pointer" }}
              >
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <h4 className="card-header-title">
                      Plan Id: {plan.planId}
                    </h4>
                    <h5 className="card-title">Field Id: {plan.fieldId}</h5>
                    <p className="card-text">
                      Plan Detail: {plan.planDetailId}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? "active" : ""
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PlanList;
