import React, { useEffect, useState } from "react";
import { getAllUsers } from "../services/AdminAPI"; // Đảm bảo đã định nghĩa hàm này trong dịch vụ
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await getAllUsers();
        if (response.data && response.data.data) {
          setUsers(response.data.data); // Thiết lập danh sách người dùng từ response
        } else {
          setUsers([]); // Nếu không có dữ liệu, thiết lập mảng rỗng
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        if (err.response) {
          if (err.response.status === 403) {
            setError("Admin only."); // Thông báo khi không có quyền
          } else {
            setError(err.response?.data?.message || "Failed to load users."); // Hiển thị thông báo lỗi cụ thể
          }
        } else {
          setError("Failed to load users."); // Thông báo lỗi mặc định
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAllUsers();
  }, []);

  const handleEditUser = (userId) => {
    navigate(`/admin/edit/${userId}`);
  };

  const handleViewUserInfo = (userId) => {
    navigate(`/admin/userinfo/${userId}`);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Admin Dashboard</h2>
      {loading ? (
        <p className="text-center" style={{ fontSize: "1.25rem" }}>
          Loading...
        </p>
      ) : error ? (
        <p className="text-center text-danger">{error}</p>
      ) : users.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.userInfoId}>
                  <td>{user.userInfoId}</td>
                  <td>{user.username}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.role}</td>
                  <td>
                    <button
                      className="btn btn-info btn-sm me-2"
                      onClick={() => handleViewUserInfo(user.userInfoId)}
                    >
                      View Info
                    </button>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={() => handleEditUser(user.userInfoId)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center" style={{ fontSize: "1.25rem" }}>
          No users found.
        </p>
      )}
    </div>
  );
};

export default AdminDashboard;