import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCountries } from '../services/areas';

const CountryList = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries(page);
        setCountries(response.data.content);
        setTotalPages(response.data.totalPages);
        console.log(response.data.content);
        console.log(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSelectCountry = (countryId) => {
    console.log('Selected Country ID:', countryId);
    if (countryId) {
      navigate(`/region/${countryId}`);
    } else {
      console.error('Country ID is undefined');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Countries</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="row">
            {countries.map((country) => (
              <div
                key={country.countryId}
                className="col-lg-4 col-md-6 mb-4"
                onClick={() => handleSelectCountry(country.countryId)}
              >
                <div className="card shadow-sm h-100 clickable-card">
                  <div className="card-body">
                    <h5 className="card-title">{country.countryName}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? 'active' : ''
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CountryList;
