import Http from "./Http";
import { getToken } from "../shared/ultils/token";

export const getAllDrone = (page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(`${process.env.REACT_APP_API_GET_ALL_DRONE}?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};

export const getDroneDetail = (droneId) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_DRONE_DETAIL}?droneId=${droneId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getSensorControlSystem = (droneId) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_SENSOR_CONTROL_SYSTEM}?droneId=${droneId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};
