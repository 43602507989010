import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/Api';
const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      alert("Email or password is not found");
      return;
    }

    try {
      
      let res = await loginUser(username, password);
      // console.log('Login response:', res.data.message.token); // Log response
  console.log(res.data.data.token);
  
      if (res && res.data.data.token ) {
        localStorage.setItem("token", res.data.data.token);
        navigate('/');
      
      } else {
        alert("Login failed.");
      }
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message); // Log error
      // alert('Login failed. Please check your credentials and try again.');
    }
  };
  
  return(
    <>
{/* ========== MAIN CONTENT ========== */}
<main id="content" role="main" className="main">
  <div className="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: 'url(assets/svg/components/abstract-bg-4.svg)'}}>
    {/* SVG Bottom Shape */}
    <figure className="position-absolute right-0 bottom-0 left-0">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
        <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    {/* End SVG Bottom Shape */}
  </div>
  {/* Content */}
  <div className="container py-5 py-sm-7">
    <a className="d-flex justify-content-center mb-5" href="index.html">
      <img className="z-index-2" src="assets\svg\logos\logo.svg" alt="Image Description" style={{width: '8rem'}} />
    </a>
    <div className="row justify-content-center">
      <div className="col-md-7 col-lg-5">
        {/* Card */}
        <div className="card card-lg mb-5">
          <div className="card-body">
            {/* Form */}
            <form onSubmit={handleLogin} className="js-validate">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-4">Sign in</h1>
                  <p>Don't have an account yet? <a href="authentication-signup-basic.html">Sign up here</a></p>
                </div>
                <a className="btn btn-lg btn-block btn-white mb-4" href="#">
                  <span className="d-flex justify-content-center align-items-center">
                    <img className="avatar avatar-xss mr-2" src="assets\svg\brands\google.svg" alt="Image Description" />
                    Sign in with Google
                  </span>
                </a>
                <span className="divider text-muted mb-4">OR</span>
              </div>
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signinSrEmail">Your email</label>
                <input 
                  type="username" 
                  className="form-control form-control-lg" 
                  name="username" 
                  id="signinSrEmail" 
                  tabIndex={1} 
                  placeholder="email@address.com" 
                  aria-label="email@address.com" 
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required 
                  data-msg="Please enter a valid email address." />
              </div>
              {/* End Form Group */}
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signupSrPassword" tabIndex={0}>
                  <span className="d-flex justify-content-between align-items-center">
                    Password
                    <a className="input-label-secondary" href="authentication-reset-password-basic.html">Forgot Password?</a>
                  </span>
                </label>
                <div className="input-group input-group-merge">
                  <input 
                  type="password" 
                  className="js-toggle-password form-control form-control-lg" 
                  name="password" 
                  id="signupSrPassword" 
                  placeholder="8+ characters required" 
                  aria-label="8+ characters required" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required 
                  data-msg="Your password is invalid. Please try again." 
                  data-hs-toggle-password-options="{
                           &quot;target&quot;: &quot;#changePassTarget&quot;,
                           &quot;defaultClass&quot;: &quot;tio-hidden-outlined&quot;,
                           &quot;showClass&quot;: &quot;tio-visible-outlined&quot;,
                           &quot;classChangeTarget&quot;: &quot;#changePassIcon&quot;
                         }" />
                  <div id="changePassTarget" className="input-group-append">
                    <a className="input-group-text" href="javascript:;">
                      <i id="changePassIcon" className="tio-visible-outlined" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Form Group */}
              {/* Checkbox */}
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="termsCheckbox" name="termsCheckbox" />
                  <label className="custom-control-label text-muted" htmlFor="termsCheckbox"> Remember me</label>
                </div>
              </div>
              {/* End Checkbox */}
              <button type="submit" className="btn btn-lg btn-block btn-primary">Sign in</button>
            </form>
            {/* End Form */}
          </div>
        </div>
        {/* End Card */}
        {/* Footer */}
        <div className="text-center">
          <small className="text-cap mb-4">Trusted by the world's best teams</small>
          <div className="w-85 mx-auto">
            <div className="row justify-content-between">
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\gitlab-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\fitbit-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\flow-xo-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\layar-gray.svg" alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </div>
    </div>
  </div>
  {/* End Content */}
</main>
{/* ========== END MAIN CONTENT ========== */}


    </>
  )
};

export default Signin;
