import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSensorControlSystem } from "../services/drone";

const SensorControlSystemDetail = () => {
  const { droneId } = useParams();
  const [sensorControlSystem, setSensorControlSystem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSCSDetail = async () => {
      try {
        const response = await getSensorControlSystem(droneId);
        if (response.data) {
          setSensorControlSystem(response.data);
        } else {
          setSensorControlSystem(null);
        }
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching scs detail:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSCSDetail();
  }, [droneId]);

  return (
    <div
      className="container mt-5 d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div className="col-md-10 col-lg-8">
        <h2 className="text-center mb-4" style={{ fontSize: "2rem" }}>
          Sensore Control System
        </h2>
        {loading ? (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Loading...
          </p>
        ) : sensorControlSystem ? (
          <div
            className="card shadow-lg hover-card"
            style={{
              padding: "2rem",
              fontSize: "1.5rem",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <div className="card-body">
              <h5
                className="card-title mb-4 text-center"
                style={{ fontSize: "2rem" }}
              >
                Drone ID: {droneId}
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Lidar Model:</strong>{" "}
                    {sensorControlSystem.lidarModel}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Camera Model:</strong>{" "}
                    {sensorControlSystem.cameraModel}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Radar Model: </strong>{" "}
                    {sensorControlSystem.radarModel}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Sensor Control System not found.
          </p>
        )}
      </div>
    </div>
  );
};

export default SensorControlSystemDetail;
