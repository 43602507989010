import React, { useState } from 'react';

const Signup = () => {
  

  
  return(
    <>
    <main id="content" role="main" className="main">
  <div className="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: '32rem', backgroundImage: 'url(assets/svg/components/abstract-bg-4.svg)'}}>
    {/* SVG Bottom Shape */}
    <figure className="position-absolute right-0 bottom-0 left-0">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
        <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    {/* End SVG Bottom Shape */}
    {/* End SVG Shapes */}
  </div>
  {/* Content */}
  <div className="container py-5 py-sm-7">
    <a className="d-flex justify-content-center mb-5" href="index.html">
      <img className="z-index-2" src="assets\svg\logos\logo.svg" alt="Image Description" style={{width: '8rem'}} />
    </a>
    <div className="row justify-content-center">
      <div className="col-md-7 col-lg-5">
        {/* Card */}
        <div className="card card-lg mb-5">
          <div className="card-body">
            {/* Form */}
            <form className="js-validate">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-4">Create your account</h1>
                  <p>Already have an account? <a href="authentication-signin-basic.html">Sign in here</a></p>
                </div>
                <a className="btn btn-lg btn-block btn-white mb-4" href="#">
                  <span className="d-flex justify-content-center align-items-center">
                    <img className="avatar avatar-xss mr-2" src="assets\svg\brands\google.svg" alt="Image Description" />
                    Sign up with Google
                  </span>
                </a>
                <span className="divider text-muted mb-4">OR</span>
              </div>
              <label className="input-label" htmlFor="fullNameSrEmail">Full name</label>
              {/* Form Group */}
              <div className="form-row">
                <div className="col-sm-6">
                  <div className="js-form-message form-group">
                    <input type="text" className="form-control form-control-lg" name="fullName" id="fullNameSrEmail" placeholder="Mark" aria-label="Mark" required data-msg="Please enter your first name." />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="js-form-message form-group">
                    <input type="text" className="form-control form-control-lg" placeholder="Williams" aria-label="Williams" required data-msg="Please enter your last name." />
                  </div>
                </div>
              </div>
              {/* End Form Group */}
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signupSrEmail">Your email</label>
                <input type="email" className="form-control form-control-lg" name="email" id="signupSrEmail" placeholder="Markwilliams@example.com" aria-label="Markwilliams@example.com" required data-msg="Please enter a valid email address." />
              </div>
              {/* End Form Group */}
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signupSrPassword">Password</label>
                <div className="input-group input-group-merge">
                  <input type="password" className="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ characters required" aria-label="8+ characters required" required data-msg="Your password is invalid. Please try again." data-hs-toggle-password-options="{
                         &quot;target&quot;: [&quot;.js-toggle-password-target-1&quot;, &quot;.js-toggle-password-target-2&quot;],
                         &quot;defaultClass&quot;: &quot;tio-hidden-outlined&quot;,
                         &quot;showClass&quot;: &quot;tio-visible-outlined&quot;,
                         &quot;classChangeTarget&quot;: &quot;.js-toggle-passowrd-show-icon-1&quot;
                       }" />
                  <div className="js-toggle-password-target-1 input-group-append">
                    <a className="input-group-text" href="javascript:;">
                      <i className="js-toggle-passowrd-show-icon-1 tio-visible-outlined" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Form Group */}
              {/* Form Group */}
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signupSrConfirmPassword">Confirm password</label>
                <div className="input-group input-group-merge">
                  <input type="password" className="js-toggle-password form-control form-control-lg" name="confirmPassword" id="signupSrConfirmPassword" placeholder="8+ characters required" aria-label="8+ characters required" required data-msg="Password does not match the confirm password." data-hs-toggle-password-options="{
                         &quot;target&quot;: [&quot;.js-toggle-password-target-1&quot;, &quot;.js-toggle-password-target-2&quot;],
                         &quot;defaultClass&quot;: &quot;tio-hidden-outlined&quot;,
                         &quot;showClass&quot;: &quot;tio-visible-outlined&quot;,
                         &quot;classChangeTarget&quot;: &quot;.js-toggle-passowrd-show-icon-2&quot;
                       }" />
                  <div className="js-toggle-password-target-2 input-group-append">
                    <a className="input-group-text" href="javascript:;">
                      <i className="js-toggle-passowrd-show-icon-2 tio-visible-outlined" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Form Group */}
              {/* Checkbox */}
              <div className="js-form-message form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="termsCheckbox" name="termsCheckbox" required data-msg="Please accept our Terms and Conditions." />
                  <label className="custom-control-label text-muted" htmlFor="termsCheckbox"> I accept the <a href="#">Terms and Conditions</a></label>
                </div>
              </div>
              {/* End Checkbox */}
              <button type="submit" className="btn btn-lg btn-block btn-primary mb-2">Create an account</button>
              <button type="submit" className="btn btn-block btn-link">
                or Start your 30-day trial <i className="tio-chevron-right" />
              </button>
            </form>
            {/* End Form */}
          </div>
        </div>
        {/* End Card */}
        {/* Footer */}
        <div className="text-center">
          <small className="text-cap mb-4">Trusted by the world's best teams</small>
          <div className="w-85 mx-auto">
            <div className="row justify-content-between">
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\gitlab-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\fitbit-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\flow-xo-gray.svg" alt="Image Description" />
              </div>
              <div className="col">
                <img className="img-fluid" src="assets\svg\brands\layar-gray.svg" alt="Image Description" />
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </div>
    </div>
  </div>
  {/* End Content */}
</main>

    </>
  )
};

export default Signup;