import Http from "./Http";
import { getToken } from "../shared/ultils/token";

export const getTaskByPlan = (planId, page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_TASK_BY_PLAN}?planId=${planId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getTaskByRobot = (robotId, page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_TASK_BY_ROBOT}?robotId=${robotId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getAllTask = (page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(`${process.env.REACT_APP_API_ALL_TASK}?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};
