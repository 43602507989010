import React from "react";

const CustomerDetail = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                href="ecommerce-customers.html"
                                            >
                                                Customers
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Customer details
                                        </li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title">
                                    Anne Richard
                                </h1>
                            </div>
                            <div className="col-sm-auto">
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle mr-1"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Previous customer"
                                >
                                    <i className="tio-arrow-backward" />
                                </a>
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Next customer"
                                >
                                    <i className="tio-arrow-forward" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* End Page Header */}
                    <div className="row">
                        <div className="col-lg-8">
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Body */}
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="avatar avatar-lg avatar-circle">
                                            <img
                                                className="avatar-img"
                                                src="assets\img\160x160\img9.jpg"
                                                alt="Image Description"
                                            />
                                        </div>
                                        <div className="mx-3">
                                            <div className="d-flex mb-1">
                                                <h3 className="mb-0 mr-3">
                                                    Anne Richard
                                                </h3>
                                                {/* Unfold */}
                                                <div className="hs-unfold">
                                                    <a
                                                        className="js-hs-unfold-invoker btn btn-icon btn-xs btn-white rounded-circle"
                                                        href="javascript:;"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Edit"
                                                        data-hs-unfold-options='{
                       "target": "#editDropdown",
                       "type": "css-animation"
                     }'
                                                    >
                                                        <i className="tio-edit" />
                                                    </a>
                                                    <div
                                                        id="editDropdown"
                                                        className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-card mt-1"
                                                        style={{
                                                            minWidth: "20rem",
                                                        }}
                                                    >
                                                        {/* Card */}
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="form-row">
                                                                    <div className="col-sm-6">
                                                                        <label
                                                                            htmlFor="firstNameLabel"
                                                                            className="input-label"
                                                                        >
                                                                            First
                                                                            name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="firstName"
                                                                            id="firstNameLabel"
                                                                            placeholder="Clarice"
                                                                            aria-label="Clarice"
                                                                            defaultValue="Anna"
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <label
                                                                            htmlFor="lastNameLabel"
                                                                            className="input-label"
                                                                        >
                                                                            Last
                                                                            name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="lastName"
                                                                            id="lastNameLabel"
                                                                            placeholder="Boone"
                                                                            aria-label="Boone"
                                                                            defaultValue="Richard"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* End Row */}
                                                                <div className="d-flex justify-content-end mt-3">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-white mr-2"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-primary"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End Body */}
                                                    </div>
                                                </div>
                                                {/* End Unfold */}
                                            </div>
                                            <span className="font-size-sm">
                                                Customer for 3 months
                                            </span>
                                        </div>
                                        <div className="d-none d-sm-inline-block ml-auto text-right">
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-sm btn-white"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#actionsDropdown",
                     "type": "css-animation"
                   }'
                                                >
                                                    Actions{" "}
                                                    <i className="tio-chevron-down" />
                                                </a>
                                                <div
                                                    id="actionsDropdown"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu mt-1"
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-email-outlined dropdown-item-icon" />{" "}
                                                        Email
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-call dropdown-item-icon" />{" "}
                                                        Call
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-sync dropdown-item-icon" />{" "}
                                                        Merge
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <a
                                                        className="dropdown-item text-danger"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon text-danger" />
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </div>
                                    </div>
                                    {/* End Media */}
                                    <label className="input-label">
                                        Customer note
                                    </label>
                                    {/* Quill */}
                                    <div className="quill-custom">
                                        <div id="toolbar-container">
                                            <ul className="list-inline ql-toolbar-list">
                                                <li className="list-inline-item">
                                                    <button className="ql-bold" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-italic" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-underline" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-strike" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-link" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-image" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-blockquote" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="ql-code" />
                                                </li>
                                                <li className="list-inline-item">
                                                    <button
                                                        className="ql-list"
                                                        value="bullet"
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className="js-quill"
                                            style={{ minHeight: "10rem" }}
                                            data-hs-quill-options='{
                    "placeholder": "Start typing to leave a note...",
                    "toolbarBottom": true,
                    "modules": {
                      "toolbar": "#toolbar-container"
                    }
                   }'
                                        ></div>
                                    </div>
                                    {/* End Quill */}
                                </div>
                                {/* Body */}
                                {/* Footer */}
                                <div className="card-footer">
                                    <div className="d-flex justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-white mr-2"
                                        >
                                            Discard
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                                {/* End Footer */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <div className="row justify-content-between align-items-center flex-grow-1">
                                        <div className="col-sm mb-3 mb-sm-0">
                                            <h4 className="card-header-title">
                                                Orders placed
                                            </h4>
                                        </div>
                                        <div className="col-sm-auto">
                                            {/* Nav */}
                                            <ul
                                                className="js-tabs-to-dropdown nav nav-segment nav-fill nav-sm-down-break"
                                                data-hs-transform-tabs-to-btn-options='{
                    "transformResolution": "sm",
                    "btnClassNames": "btn btn-block btn-white dropdown-toggle justify-content-center"
                  }'
                                            >
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        href="#"
                                                    >
                                                        All orders
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link disabled"
                                                        href="#"
                                                        tabIndex={-1}
                                                        aria-disabled="true"
                                                    >
                                                        Open
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link disabled"
                                                        href="#"
                                                        tabIndex={-1}
                                                        aria-disabled="true"
                                                    >
                                                        Unfulfilled
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link disabled"
                                                        href="#"
                                                        tabIndex={-1}
                                                        aria-disabled="true"
                                                    >
                                                        Unpaid
                                                    </a>
                                                </li>
                                            </ul>
                                            {/* End Nav */}
                                            {/* Datatable Info */}
                                            <div
                                                id="datatableCounterInfo"
                                                style={{ display: "none" }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <span className="font-size-sm mr-3">
                                                        <span id="datatableCounter">
                                                            0
                                                        </span>
                                                        Selected
                                                    </span>
                                                    <a
                                                        className="btn btn-sm btn-outline-danger"
                                                        href="javascript:;"
                                                    >
                                                        <i className="tio-delete-outlined" />{" "}
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Datatable Info */}
                                        </div>
                                    </div>
                                    {/* End Row */}
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Input Group */}
                                    <div className="input-group input-group-merge">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="tio-search" />
                                            </span>
                                        </div>
                                        <input
                                            id="datatableSearch"
                                            type="search"
                                            className="form-control"
                                            placeholder="Search orders"
                                            aria-label="Search orders"
                                        />
                                    </div>
                                    {/* End Input Group */}
                                </div>
                                {/* End Body */}
                                {/* Table */}
                                <div className="table-responsive datatable-custom">
                                    <table
                                        id="datatable"
                                        className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                        data-hs-datatables-options='{
                   "columnDefs": [{
                      "targets": [0, 5],
                      "orderable": false
                    }],
                   "order": [],
                   "info": {
                     "totalQty": "#datatableWithPaginationInfoTotalQty"
                   },
                   "search": "#datatableSearch",
                   "entries": "#datatableEntries",
                   "pageLength": 12,
                   "isResponsive": false,
                   "isShowPaging": false,
                   "pagination": "datatablePagination"
                 }'
                                    >
                                        <thead className="thead-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="table-column-pr-0"
                                                >
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="datatableCheckAll"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="datatableCheckAll"
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    Order
                                                </th>
                                                <th>Date</th>
                                                <th>Payment status</th>
                                                <th>Total</th>
                                                <th>Invoice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck1"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck1"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #35463
                                                    </a>
                                                </td>
                                                <td>Aug 17, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$256.39</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck2"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck2"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #23513
                                                    </a>
                                                </td>
                                                <td>Aug 17, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$2,125.00</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck3"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck3"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #62311
                                                    </a>
                                                </td>
                                                <td>Aug 03, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$532.99</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck4"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck4"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #12453
                                                    </a>
                                                </td>
                                                <td>July 29, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-warning">
                                                        <span className="legend-indicator bg-warning" />
                                                        Pending
                                                    </span>
                                                </td>
                                                <td>$1,035.02</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck5"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck5"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #84223
                                                    </a>
                                                </td>
                                                <td>July 11, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$68.53</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck6"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck6"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #46542
                                                    </a>
                                                </td>
                                                <td>July 04, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$100.00</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck7"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck7"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a
                                                        className="text-danger"
                                                        href="ecommerce-order-details.html"
                                                    >
                                                        #35378
                                                    </a>
                                                    <i className="tio-warning text-warning" />
                                                </td>
                                                <td>June 27, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-warning">
                                                        <span className="legend-indicator bg-warning" />
                                                        Pending
                                                    </span>
                                                </td>
                                                <td className="text-danger">
                                                    $89.46
                                                </td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck8"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck8"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a
                                                        className="text-danger"
                                                        href="ecommerce-order-details.html"
                                                    >
                                                        #24562
                                                    </a>
                                                    <i className="tio-warning text-warning" />
                                                </td>
                                                <td>June 15, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-warning">
                                                        <span className="legend-indicator bg-warning" />
                                                        Pending
                                                    </span>
                                                </td>
                                                <td className="text-danger">
                                                    $3,535.46
                                                </td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck9"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck9"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #78531
                                                    </a>
                                                </td>
                                                <td>June 12, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$23.89</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck10"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck10"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #34634
                                                    </a>
                                                </td>
                                                <td>June 02, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$77.00</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck11"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck11"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a
                                                        className="text-danger"
                                                        href="ecommerce-order-details.html"
                                                    >
                                                        #93817
                                                    </a>
                                                    <i className="tio-warning text-warning" />
                                                </td>
                                                <td>May 30, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-warning">
                                                        <span className="legend-indicator bg-warning" />
                                                        Pending
                                                    </span>
                                                </td>
                                                <td className="text-danger">
                                                    $77.00
                                                </td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck12"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck12"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #43113
                                                    </a>
                                                </td>
                                                <td>May 25, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$1,421.47</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="ordersCheck13"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="ordersCheck13"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-column-pl-0">
                                                    <a href="ecommerce-order-details.html">
                                                        #12412
                                                    </a>
                                                </td>
                                                <td>May 16, 2020</td>
                                                <td>
                                                    <span className="badge badge-soft-success">
                                                        <span className="legend-indicator bg-success" />
                                                        Paid
                                                    </span>
                                                </td>
                                                <td>$45.00</td>
                                                <td>
                                                    <a
                                                        className="btn btn-sm btn-white"
                                                        href="javascript:;"
                                                        data-toggle="modal"
                                                        data-target="#invoiceReceiptModal"
                                                    >
                                                        <i className="tio-receipt-outlined mr-1" />{" "}
                                                        Invoice
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* End Table */}
                                {/* Footer */}
                                <div className="card-footer">
                                    {/* Pagination */}
                                    <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                        <div className="col-sm mb-2 mb-sm-0">
                                            <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                                <span className="mr-2">
                                                    Showing:
                                                </span>
                                                {/* Select */}
                                                <select
                                                    id="datatableEntries"
                                                    className="js-select2-custom"
                                                    data-hs-select2-options='{
                          "minimumResultsForSearch": "Infinity",
                          "customClass": "custom-select custom-select-sm custom-select-borderless",
                          "dropdownAutoWidth": true,
                          "width": true
                        }'
                                                >
                                                    <option value={12} selected>
                                                        12
                                                    </option>
                                                    <option value={14}>
                                                        14
                                                    </option>
                                                    <option value={16}>
                                                        16
                                                    </option>
                                                    <option value={18}>
                                                        18
                                                    </option>
                                                </select>
                                                {/* End Select */}
                                                <span className="text-secondary mr-2">
                                                    of
                                                </span>
                                                {/* Pagination Quantity */}
                                                <span id="datatableWithPaginationInfoTotalQty" />
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="d-flex justify-content-center justify-content-sm-end">
                                                {/* Pagination */}
                                                <nav
                                                    id="datatablePagination"
                                                    aria-label="Activity pagination"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Pagination */}
                                </div>
                                {/* End Footer */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Timeline
                                    </h4>
                                    {/* Checkbox */}
                                    <div className="custom-control custom-checkbox font-size-sm">
                                        <input
                                            id="showCommentsCheckbox"
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="showCommentsCheckbox"
                                        >
                                            Show comments
                                        </label>
                                    </div>
                                    {/* End Checkbox */}
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Step */}
                                    <ul className="step step-icon-sm">
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-primary">
                                                    A
                                                </span>
                                                {/* Quill */}
                                                <div className="quill-custom">
                                                    <div id="step-toolbar-container">
                                                        <ul className="list-inline ql-toolbar-list">
                                                            <li className="list-inline-item">
                                                                <button className="ql-bold" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-italic" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-underline" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-strike" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-link" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-image" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-blockquote" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button className="ql-code" />
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <button
                                                                    className="ql-list"
                                                                    value="bullet"
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        className="js-quill-step"
                                                        data-hs-quill-options='{
                          "placeholder": "Leave a comment...",
                          "toolbarBottom": true,
                          "modules": {
                            "toolbar": "#step-toolbar-container"
                          }
                         }'
                                                    ></div>
                                                </div>
                                                {/* End Quill */}
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <small className="step-divider">
                                                    Wednesday, 19 August
                                                </small>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-0">
                                                        You submitted a customer
                                                        data request.
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        10:19 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-0">
                                                        You added the email
                                                        anne@gmail.com to this
                                                        customer.
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        10:18 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-0">
                                                        You created this
                                                        customer.
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        10:18 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                    </ul>
                                    {/* End Step */}
                                </div>
                                {/* End Body */}
                            </div>
                            {/* End Card */}
                            <div className="d-none d-lg-block">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                >
                                    Delete customer
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Body */}
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Contact info</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    <ul className="list-unstyled list-unstyled-py-2">
                                        <li>
                                            <i className="tio-online mr-2" />
                                            anne@example.com
                                        </li>
                                        <li>
                                            <i className="tio-android-phone-vs mr-2" />
                                            +1 (609) 972-22-22
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Shipping address</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    {/* Leaflet (Map) */}
                                    <div
                                        id="map"
                                        className="leaflet-custom rounded mt-1 mb-3"
                                        style={{ minHeight: "10rem" }}
                                        data-hs-leaflet-options='{
                 "map": {
                   "scrollWheelZoom": false,
                   "coords": [37.4040344, -122.0289704]
                 },
                 "marker": [
                   {
                     "coords": [37.4040344, -122.0289704],
                     "icon": {
                       "iconUrl": "./assets/svg/components/map-pin.svg",
                       "iconSize": [50, 45]
                     },
                     "popup": {
                       "text": "153 Williamson Plaza, Maggieberg"
                     }
                   }
                 ]
                }'
                                    />
                                    {/* End Leaflet (Map) */}
                                    <span className="d-block">
                                        45 Roker Terrace
                                        <br />
                                        Latheronwheel
                                        <br />
                                        KW5 8NW, London
                                        <br />
                                        UK{" "}
                                        <img
                                            className="avatar avatar-xss avatar-circle ml-1"
                                            src="assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                                            alt="Great Britain Flag"
                                        />
                                    </span>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Billing address</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    <span className="d-block">
                                        45 Roker Terrace
                                        <br />
                                        Latheronwheel
                                        <br />
                                        KW5 8NW, London
                                        <br />
                                        UK{" "}
                                        <img
                                            className="avatar avatar-xss avatar-circle ml-1"
                                            src="assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                                            alt="Great Britain Flag"
                                        />
                                    </span>
                                    <div className="mt-3">
                                        <h5 className="mb-0">Mastercard</h5>
                                        <span className="d-block">
                                            Card Number: ************4291
                                        </span>
                                    </div>
                                </div>
                                {/* End Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h5>Email marketing</h5>
                                    <a className="link" href="javascript:;">
                                        Edit status
                                    </a>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    <span className="h3">
                                        <span className="badge badge-soft-dark badge-pill">
                                            Subscribed
                                        </span>
                                    </span>
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Website activity
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    <p>
                                        Website activity shows you how many
                                        times a contact has visited your site
                                        and viewed your pages.
                                    </p>
                                    {/* Bar Chart */}
                                    <div
                                        className="chartjs-custom my-5"
                                        style={{ height: "12rem" }}
                                    >
                                        <canvas
                                            className="js-chart"
                                            data-hs-chartjs-options='{
                      "type": "line",
                      "data": {
                         "labels": ["Aug 1", "Aug 2", "Aug 3", "Aug 4", "Aug 5"],
                         "datasets": [{
                          "data": [10, 9, 14, 5, 10],
                          "backgroundColor": "transparent",
                          "borderColor": "#377dff",
                          "borderWidth": 3,
                          "pointRadius": 0,
                          "hoverBorderColor": "#377dff",
                          "pointBackgroundColor": "#377dff",
                          "pointBorderColor": "#fff",
                          "pointHoverRadius": 0
                        },
                        {
                          "data": [15, 13, 18, 7, 26],
                          "backgroundColor": "transparent",
                          "borderColor": "#e7eaf3",
                          "borderWidth": 3,
                          "pointRadius": 0,
                          "hoverBorderColor": "#e7eaf3",
                          "pointBackgroundColor": "#e7eaf3",
                          "pointBorderColor": "#fff",
                          "pointHoverRadius": 0
                        }]
                      },
                      "options": {
                         "scales": {
                            "yAxes": [{
                              "gridLines": {
                                "color": "#e7eaf3",
                                "drawBorder": false,
                                "zeroLineColor": "#e7eaf3"
                              },
                              "ticks": {
                                "stepSize": 10,
                                "fontSize": 12,
                                "fontColor": "#97a4af",
                                "fontFamily": "Open Sans, sans-serif",
                                "padding": 10
                              }
                            }],
                            "xAxes": [{
                              "display": false
                            }]
                        },
                        "tooltips": {
                          "hasIndicator": true,
                          "mode": "index",
                          "intersect": false,
                          "lineMode": true,
                          "lineWithLineColor": "rgba(19, 33, 68, 0.075)"
                        },
                        "hover": {
                          "mode": "nearest",
                          "intersect": true
                        }
                      }
                    }'
                                        ></canvas>
                                    </div>
                                    {/* End Bar Chart */}
                                    {/* Legend Indicators */}
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <span className="legend-indicator" />{" "}
                                            Yesterday
                                        </div>
                                        <div className="col-auto">
                                            <span className="legend-indicator bg-primary" />{" "}
                                            Today
                                        </div>
                                    </div>
                                    {/* End Legend Indicators */}
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                        </div>
                    </div>
                    {/* End Row */}
                    <div className="d-lg-none">
                        <button type="button" className="btn btn-danger">
                            Delete customer
                        </button>
                    </div>
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="footer">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <p className="font-size-sm mb-0">
                                © Front.{" "}
                                <span className="d-none d-sm-inline-block">
                                    2020 Htmlstream.
                                </span>
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-end">
                                {/* List Dot */}
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            License
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* Keyboard Shortcuts Toggle */}
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                                            >
                                                <i className="tio-command-key" />
                                            </a>
                                        </div>
                                        {/* End Keyboard Shortcuts Toggle */}
                                    </li>
                                </ul>
                                {/* End List Dot */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default CustomerDetail;
