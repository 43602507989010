import React from "react";

const ManageReviews = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    {/* Page Header */}
                    <div className="page-header">
                        <h1 className="page-header-title">Manage reviews</h1>
                        {/* Nav */}
                        <ul className="nav nav-tabs page-header-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">
                                    Published
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    Pending{" "}
                                    <span className="badge badge-soft-dark badge-pill ml-1">
                                        2
                                    </span>
                                </a>
                            </li>
                        </ul>
                        {/* End Nav */}
                    </div>
                    {/* End Page Header */}
                    {/* Card */}
                    <div className="card mb-3 mb-lg-5">
                        {/* Body */}
                        <div className="card-body">
                            <div className="row align-items-md-center gx-md-5">
                                <div className="col-md-auto mb-3 mb-md-0">
                                    <div className="d-flex align-items-center">
                                        <img
                                            className="avatar avatar-xxl avatar-4by3 mr-4"
                                            src="assets\svg\illustrations\review-5-star.svg"
                                            alt="Image Description"
                                        />
                                        <div className="d-block">
                                            <h4 className="display-2 text-dark mb-0">
                                                4.84
                                            </h4>
                                            <p>
                                                — of 7 reviews{" "}
                                                <span className="badge badge-soft-dark badge-pill ml-1">
                                                    +1 this week
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <ul className="list-unstyled list-unstyled-py-2 mb-0">
                                        {/* Review Ratings */}
                                        <li className="d-flex align-items-center font-size-sm">
                                            <span className="mr-3">5 star</span>
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "82%" }}
                                                    aria-valuenow={82}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-3">4</span>
                                        </li>
                                        {/* End Review Ratings */}
                                        {/* Review Ratings */}
                                        <li className="d-flex align-items-center font-size-sm">
                                            <span className="mr-3">4 star</span>
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "18%" }}
                                                    aria-valuenow={18}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-3">3</span>
                                        </li>
                                        {/* End Review Ratings */}
                                        {/* Review Ratings */}
                                        <li className="d-flex align-items-center font-size-sm">
                                            <span className="mr-3">3 star</span>
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "0%" }}
                                                    aria-valuenow={0}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-3">0</span>
                                        </li>
                                        {/* End Review Ratings */}
                                        {/* Review Ratings */}
                                        <li className="d-flex align-items-center font-size-sm">
                                            <span className="mr-3">2 star</span>
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "0%" }}
                                                    aria-valuenow={0}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-3">0</span>
                                        </li>
                                        {/* End Review Ratings */}
                                        {/* Review Ratings */}
                                        <li className="d-flex align-items-center font-size-sm">
                                            <span className="mr-3">1 star</span>
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "0%" }}
                                                    aria-valuenow={0}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-3">0</span>
                                        </li>
                                        {/* End Review Ratings */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End Body */}
                    </div>
                    {/* End Card */}
                    {/* Card */}
                    <div className="card">
                        {/* Header */}
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center flex-grow-1">
                                <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
                                    <form>
                                        {/* Search */}
                                        <div className="input-group input-group-merge input-group-flush">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="tio-search" />
                                                </div>
                                            </div>
                                            <input
                                                id="datatableSearch"
                                                type="search"
                                                className="form-control"
                                                placeholder="Search reviews"
                                                aria-label="Search reviews"
                                            />
                                        </div>
                                        {/* End Search */}
                                    </form>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-sm-flex justify-content-sm-end align-items-sm-center">
                                        {/* Datatable Info */}
                                        <div
                                            id="datatableCounterInfo"
                                            className="mr-2 mb-2 mb-sm-0"
                                            style={{ display: "none" }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <span className="font-size-sm mr-3">
                                                    <span id="datatableCounter">
                                                        0
                                                    </span>
                                                    Selected
                                                </span>
                                                <a
                                                    className="btn btn-sm btn-outline-danger mr-2"
                                                    href="javascript:;"
                                                >
                                                    <i className="tio-delete-outlined" />{" "}
                                                    Delete
                                                </a>
                                                <a
                                                    className="btn btn-sm btn-primary"
                                                    href="javascript:;"
                                                >
                                                    <i className="tio-publish" />{" "}
                                                    Publish
                                                </a>
                                            </div>
                                        </div>
                                        {/* End Datatable Info */}
                                        {/* Unfold */}
                                        <div className="hs-unfold mr-2">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-sm btn-white dropdown-toggle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                   "target": "#usersExportDropdown",
                   "type": "css-animation"
                 }'
                                            >
                                                <i className="tio-download-to mr-1" />{" "}
                                                Export
                                            </a>
                                            <div
                                                id="usersExportDropdown"
                                                className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-sm-right"
                                            >
                                                <span className="dropdown-header">
                                                    Options
                                                </span>
                                                <a
                                                    id="export-copy"
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-4by3 mr-2"
                                                        src="assets\svg\illustrations\copy.svg"
                                                        alt="Image Description"
                                                    />
                                                    Copy
                                                </a>
                                                <a
                                                    id="export-print"
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-4by3 mr-2"
                                                        src="assets\svg\illustrations\print.svg"
                                                        alt="Image Description"
                                                    />
                                                    Print
                                                </a>
                                                <div className="dropdown-divider" />
                                                <span className="dropdown-header">
                                                    Download options
                                                </span>
                                                <a
                                                    id="export-excel"
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-4by3 mr-2"
                                                        src="assets\svg\brands\excel.svg"
                                                        alt="Image Description"
                                                    />
                                                    Excel
                                                </a>
                                                <a
                                                    id="export-csv"
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-4by3 mr-2"
                                                        src="assets\svg\components\placeholder-csv-format.svg"
                                                        alt="Image Description"
                                                    />
                                                    .CSV
                                                </a>
                                                <a
                                                    id="export-pdf"
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-4by3 mr-2"
                                                        src="assets\svg\brands\pdf.svg"
                                                        alt="Image Description"
                                                    />
                                                    PDF
                                                </a>
                                            </div>
                                        </div>
                                        {/* End Unfold */}
                                        {/* Select */}
                                        <select
                                            className="js-select2-custom js-datatable-filter custom-select-sm"
                                            size={1}
                                            style={{ opacity: 0 }}
                                            data-target-column-index={5}
                                            data-hs-select2-options='{
                      "minimumResultsForSearch": "Infinity",
                      "customClass": "custom-select custom-select-sm",
                      "dropdownAutoWidth": true,
                      "width": true,
                      "placeholder": "Filters"
                    }'
                                        >
                                            <option value>All</option>
                                            <option value="Published">
                                                Published
                                            </option>
                                            <option value="Pending">
                                                Pending
                                            </option>
                                        </select>
                                        {/* End Select */}
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                        {/* End Header */}
                        {/* Table */}
                        <div className="table-responsive datatable-custom">
                            <table
                                id="datatable"
                                className="table table-borderless table-thead-bordered table-nowrap card-table"
                                data-hs-datatables-options='{
               "columnDefs": [{
                  "targets": [0, 3, 6],
                  "orderable": false
                }],
               "order": [],
               "info": {
                 "totalQty": "#datatableWithPaginationInfoTotalQty"
               },
               "search": "#datatableSearch",
               "entries": "#datatableEntries",
               "pageLength": 5,
               "isResponsive": false,
               "isShowPaging": false,
               "pagination": "datatablePagination"
             }'
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="table-column-pr-0"
                                        >
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    id="datatableCheckAll"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="datatableCheckAll"
                                                />
                                            </div>
                                        </th>
                                        <th>Product</th>
                                        <th>Reviewer</th>
                                        <th>Review</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck2"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck2"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img18.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Brown Hat
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-circle">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\160x160\img10.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        Amanda Harvey{" "}
                                                        <i
                                                            className="tio-verified text-primary"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Verified purchase"
                                                        />
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        amanda@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    I just love it!
                                                </h4>
                                                <p>
                                                    I bought this hat for my
                                                    boyfriend, but then i found
                                                    out he cheated on me so I
                                                    kept it and I love it!! I
                                                    wear it all the time and
                                                    there is no problem with the
                                                    fit even though its a mens"
                                                    hat.
                                                </p>
                                            </div>
                                        </td>
                                        <td>Aug 17, 2020, 5:48</td>
                                        <td>
                                            <span className="badge badge-soft-success ml-2">
                                                Published
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown1",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown1"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck3"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck3"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img3.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Calvin Klein t-shirts
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-soft-dark avatar-circle">
                                                    <span className="avatar-initials">
                                                        A
                                                    </span>
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        Anne Richard
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        anne@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    Really nice
                                                </h4>
                                                <p>
                                                    Material is great and very
                                                    comfortable and stylish.
                                                </p>
                                            </div>
                                        </td>
                                        <td>Aug 04, 2020, 3:17</td>
                                        <td>
                                            <span className="badge badge-soft-warning ml-2">
                                                Pending
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown2",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown2"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck4"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck4"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img24.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Clarks shoes
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-circle">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\160x160\img3.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        David Harrison
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        david@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star-muted.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    Good product
                                                </h4>
                                                <p>
                                                    A really well built shoe. It
                                                    looks great and wears just
                                                    as well. A great staple in
                                                    ball caps.
                                                </p>
                                            </div>
                                        </td>
                                        <td>June 18, 2020, 09:19</td>
                                        <td>
                                            <span className="badge badge-soft-success ml-2">
                                                Published
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown3",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown3"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck5"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck5"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img19.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Levis women's jeans
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-circle">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\160x160\img5.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        David Harrison
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        david@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    Buy the product, you will
                                                    not regret it!
                                                </h4>
                                                <p>
                                                    Don't let this merchandise
                                                    get away! It's a must buy
                                                    and you will look good in it
                                                    while working out.
                                                </p>
                                            </div>
                                        </td>
                                        <td>June 08, 2020, 07:22</td>
                                        <td>
                                            <span className="badge badge-soft-success ml-2">
                                                Published
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown4",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown4"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck6"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck6"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img11.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Asos t-shirts
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-soft-dark avatar-circle">
                                                    <span className="avatar-initials">
                                                        B
                                                    </span>
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        Bob Dean
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        bob@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star-muted.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    Ready for the heat!
                                                </h4>
                                                <p>
                                                    As good as the heat Rdy
                                                    T-shirt but without the
                                                    sleeves. Love the stripes on
                                                    the back.
                                                </p>
                                            </div>
                                        </td>
                                        <td>May 27, 2020, 04:01</td>
                                        <td>
                                            <span className="badge badge-soft-warning ml-2">
                                                Pending
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown5",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown5"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck7"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck7"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Tiro track jacket
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-circle">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\160x160\img9.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        Ella Lauda{" "}
                                                        <i
                                                            className="tio-verified text-primary"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Top endorsed"
                                                        />
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        ella@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star-muted.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    Very nice
                                                </h4>
                                                <p>
                                                    Great quality, it is a loose
                                                    fitting but I would
                                                    recommend going one size
                                                    smaller as normal. I always
                                                    prefer bigger size so bought
                                                    large for me. Love the pride
                                                    vibes!!!
                                                </p>
                                            </div>
                                        </td>
                                        <td>June 08, 2020, 07:22</td>
                                        <td>
                                            <span className="badge badge-soft-success ml-2">
                                                Published
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown6",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown6"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-column-pr-0">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="usersDataCheck8"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="usersDataCheck8"
                                                />
                                            </div>
                                        </td>
                                        <th>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar">
                                                    <img
                                                        className="avatar-img"
                                                        src="assets\img\400x400\img14.jpg"
                                                        alt="Image Description"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <span className="h5 text-hover-primary">
                                                        Air Jordan 1
                                                    </span>
                                                </div>
                                            </a>
                                        </th>
                                        <td>
                                            <a
                                                className="d-flex align-items-center"
                                                href="user-profile.html"
                                            >
                                                <div className="avatar avatar-soft-info avatar-circle">
                                                    <span className="avatar-initials">
                                                        L
                                                    </span>
                                                </div>
                                                <div className="ml-3">
                                                    <span className="d-block h5 text-hover-primary mb-0">
                                                        Lori Hunter{" "}
                                                        <i
                                                            className="tio-verified text-primary"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Top endorsed"
                                                        />
                                                    </span>
                                                    <span className="d-block font-size-sm text-body">
                                                        lori@example.com
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <div
                                                className="text-wrap"
                                                style={{ width: "18rem" }}
                                            >
                                                <div className="d-flex mb-2">
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                    <div className="mr-1">
                                                        <img
                                                            src="assets\svg\components\star.svg"
                                                            alt="Review rating"
                                                            width={14}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-1">
                                                    The hype is real!
                                                </h4>
                                                <p>
                                                    I am very pleased with my
                                                    purchase. I have started to
                                                    have my friends become
                                                    addicted to Air Jordans
                                                    also.
                                                </p>
                                            </div>
                                        </td>
                                        <td>May 01, 2020, 01:36</td>
                                        <td>
                                            <span className="badge badge-soft-success ml-2">
                                                Published
                                            </span>
                                        </td>
                                        <td>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#settingsDropdown7",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-horizontal" />
                                                </a>
                                                <div
                                                    id="settingsDropdown7"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit dropdown-item-icon" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-publish dropdown-item-icon" />{" "}
                                                        Publish
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined dropdown-item-icon" />{" "}
                                                        Delete
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-reply dropdown-item-icon" />{" "}
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* End Table */}
                        {/* Footer */}
                        <div className="card-footer">
                            {/* Pagination */}
                            <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                <div className="col-sm mb-2 mb-sm-0">
                                    <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                        <span className="mr-2">Showing:</span>
                                        {/* Select */}
                                        <select
                                            id="datatableEntries"
                                            className="js-select2-custom"
                                            data-hs-select2-options='{
                      "minimumResultsForSearch": "Infinity",
                      "customClass": "custom-select custom-select-sm custom-select-borderless",
                      "dropdownAutoWidth": true,
                      "width": true
                    }'
                                        >
                                            <option value={5} selected>
                                                5
                                            </option>
                                            <option value={7}>7</option>
                                            <option value={12}>12</option>
                                        </select>
                                        {/* End Select */}
                                        <span className="text-secondary mr-2">
                                            of
                                        </span>
                                        {/* Pagination Quantity */}
                                        <span id="datatableWithPaginationInfoTotalQty" />
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="d-flex justify-content-center justify-content-sm-end">
                                        {/* Pagination */}
                                        <nav
                                            id="datatablePagination"
                                            aria-label="Activity pagination"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* End Pagination */}
                        </div>
                        {/* End Footer */}
                    </div>
                    {/* End Card */}
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="footer">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <p className="font-size-sm mb-0">
                                © Front.{" "}
                                <span className="d-none d-sm-inline-block">
                                    2020 Htmlstream.
                                </span>
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-end">
                                {/* List Dot */}
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            License
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* Keyboard Shortcuts Toggle */}
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                                            >
                                                <i className="tio-command-key" />
                                            </a>
                                        </div>
                                        {/* End Keyboard Shortcuts Toggle */}
                                    </li>
                                </ul>
                                {/* End List Dot */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default ManageReviews;
