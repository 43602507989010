import Http from "./Http";
import { getToken } from "../shared/ultils/token";

export const createUserProfile = (
  firstName,
  lastName,
  gender,
  doB,
  address,
  email,
  phoneNumber
) => {
  const token = getToken();
  return Http.post(
    process.env.REACT_APP_API_CREATE_USER_PROFILE,
    { firstName, lastName, gender, doB, address, token, email, phoneNumber },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getUserProfile = () => {
  const token = getToken();
  return Http.get(process.env.REACT_APP_API_GET_USER_PROFILE, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};

export const updateUserProfile = (
  firstName,
  lastName,
  gender,
  doB,
  address,
  email,
  phoneNumber
) => {
  const token = getToken();
  return Http.put(
    process.env.REACT_APP_API_UPDATE_USER_PROFILE,
    { firstName, lastName, gender, doB, address, token, email, phoneNumber },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getAddress = () => {
  const token = getToken();
  return Http.get(process.env.REACT_APP_API_GET_ADDRESS, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};
