import React from "react";

const NotFound404 = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    <div className="row align-items-sm-center py-sm-10">
                        <div className="col-sm-6">
                            <div className="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
                                <img
                                    className="w-60 w-sm-100 mx-auto"
                                    src="assets\svg\illustrations\think.svg"
                                    alt="Image Description"
                                    style={{ maxWidth: "15rem" }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 text-center text-sm-left">
                            <h1 className="display-1 mb-0">404</h1>
                            <p className="lead">
                                Sorry, the page you're looking for cannot be
                                found.
                            </p>
                            <a className="btn btn-primary" href="index.html">
                                Go back to the App
                            </a>
                        </div>
                    </div>
                    {/* End Row */}
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="footer">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <p className="font-size-sm mb-0">
                                © Front.{" "}
                                <span className="d-none d-sm-inline-block">
                                    2020 Htmlstream.
                                </span>
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-end">
                                {/* List Dot */}
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            License
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* Keyboard Shortcuts Toggle */}
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                                            >
                                                <i className="tio-command-key" />
                                            </a>
                                        </div>
                                        {/* End Keyboard Shortcuts Toggle */}
                                    </li>
                                </ul>
                                {/* End List Dot */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default NotFound404;
