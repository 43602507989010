import React, { useEffect, useState } from "react";
import {
  createUserProfile,
  getUserProfile,
  updateUserProfile,
  getAddress,
} from "../services/setting.js";

const Settings = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [doB, setDoB] = useState("");
  const [address, setAddress] = useState("");
  const [listAddress, setListAddress] = useState([]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        setProfile(response.data);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
        setGender(response.data.gender);
        setDoB(response.data.doB);
        setAddress(response.data.address);
        setPhoneNumber(response.data.phoneNumber);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      });

    getAddress()
      .then((response) => setListAddress(response.data))
      .catch((error) => {
        console.error("Error fetching list adddress:", error);
      });
  }, []);

  const handleSaveProfile = () => {
    if (profile) {
      updateUserProfile(
        firstName,
        lastName,
        gender,
        doB,
        address,
        email,
        phoneNumber
      )
        .then((response) => {
          console.log("Profile updated:", response.data);
          alert(response.data); // Hiển thị thông báo thành công
          setProfile(response.data);
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
          alert(error.response.data.message);
        });
    } else {
      createUserProfile(
        firstName,
        lastName,
        gender,
        doB,
        address,
        email,
        phoneNumber
      )
        .then((response) => {
          console.log("Profile created:", response.data);
          alert(response.data); // Hiển thị thông báo thành công
          setProfile(response.data);
        })
        .catch((error) => {
          console.error("Error creating profile:", error);
          alert(error.response.data.message);
        });
    }
  };

  const handleDateChange = (e) => {
    setDoB(e.target.value); // Cập nhật giá trị ngày sinh khi người dùng chọn ngày
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <main id="content" role="main" className="main">
        {/* Content */}
        <div className="">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-no-gutter">
                    <li className="breadcrumb-item">
                      <a className="breadcrumb-link" href="javascript:;">
                        Pages
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a className="breadcrumb-link" href="javascript:;">
                        Account
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Settings
                    </li>
                  </ol>
                </nav>
                <h1 className="page-header-title">Settings</h1>
              </div>
              <div className="col-sm-auto">
                <a
                  className="btn btn-primary"
                  href="user-profile-my-profile.html"
                >
                  <i className="tio-user mr-1" /> My profile
                </a>
              </div>
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-9">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Profile Cover */}
                <div className="profile-cover">
                  <div className="profile-cover-img-wrapper">
                    <img
                      id="profileCoverImg"
                      className="profile-cover-img"
                      src="assets\img\1920x400\img2.jpg"
                      alt="Image Description"
                    />
                    {/* Custom File Cover */}
                    <div className="profile-cover-content profile-cover-btn">
                      <div className="custom-file-btn">
                        <input
                          type="file"
                          className="js-file-attach custom-file-btn-input"
                          id="profileCoverUplaoder"
                          data-hs-file-attach-options='{
                          "textTarget": "#profileCoverImg",
                          "mode": "image",
                          "targetAttr": "src",
                          "allowTypes": [".png", ".jpeg", ".jpg"]
                       }'
                        />
                        <label
                          className="custom-file-btn-label btn btn-sm btn-white"
                          htmlFor="profileCoverUplaoder"
                        >
                          <i className="tio-add-photo mr-sm-1" />
                          <span className="d-none d-sm-inline-block">
                            Update your header
                          </span>
                        </label>
                      </div>
                    </div>
                    {/* End Custom File Cover */}
                  </div>
                </div>
                {/* End Profile Cover */}
                {/* Avatar */}
                <label
                  className="avatar avatar-xxl avatar-circle avatar-border-lg avatar-uploader profile-cover-avatar"
                  htmlFor="avatarUploader"
                >
                  <img
                    id="avatarImg"
                    className="avatar-img"
                    src="assets\img\160x160\img6.jpg"
                    alt="Image Description"
                  />
                  <input
                    type="file"
                    className="js-file-attach avatar-uploader-input"
                    id="avatarUploader"
                    data-hs-file-attach-options='{
                    "textTarget": "#avatarImg",
                    "mode": "image",
                    "targetAttr": "src",
                    "allowTypes": [".png", ".jpeg", ".jpg"]
                 }'
                  />
                  <span className="avatar-uploader-trigger">
                    <i className="tio-edit avatar-uploader-icon shadow-soft" />
                  </span>
                </label>
                {/* End Avatar */}
                {/* Body */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-5">
                      <span className="d-block font-size-sm mb-2">
                        Who can see your profile photo?{" "}
                        <i
                          className="tio-help-outlined"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."
                        />
                      </span>
                      {/* Select */}
                      <div className="select2-custom">
                        <select
                          className="js-select2-custom custom-select"
                          size={1}
                          style={{ opacity: 0 }}
                          data-hs-select2-options='{
                          "minimumResultsForSearch": "Infinity"
                        }'
                        >
                          <option
                            value="privacy1"
                            data-option-template='<span class="media"><i class="tio-earth-east tio-lg text-body mr-2" style="margin-top: .125rem;"></i><span class="media-body"><span class="d-block">Anyone</span><small class="select2-custom-hide">Visible to anyone who can view your content. Accessible by installed apps.</small></span></span>'
                          >
                            Anyone
                          </option>
                          <option
                            value="privacy2"
                            data-option-template='<span class="media"><i class="tio-lock-outlined tio-lg text-body mr-2" style="margin-top: .125rem;"></i><span class="media-body"><span class="d-block">Only you</span><small class="select2-custom-hide">Only visible to you.</small></span></span>'
                          >
                            Only you
                          </option>
                        </select>
                      </div>
                      {/* End Select */}
                    </div>
                  </div>
                  {/* End Row */}
                </div>
                {/* End Body */}
              </div>
              {/* End Card */}
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <h2 className="card-title h4">Basic information</h2>
                </div>
                {/* Body */}
                <div className="card-body">
                  {/* Form */}
                  <form>
                    {/* Form Group */}
                    <div className="row form-group">
                      <label
                        htmlFor="firstNameLabel"
                        className="col-sm-3 col-form-label input-label font-weight-bold"
                      >
                        Full name{" "}
                        <i
                          className="tio-help-outlined text-body ml-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Displayed on public forums, such as Front."
                        />
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm-down-break">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            id="firstNameLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastNameLabel"
                            placeholder="Your last name"
                            aria-label="Your last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div id="accountType" className="row form-group">
                      <label className="col-sm-3 col-form-label input-label  font-weight-bold">
                        Gender
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm-down-break">
                          {/* Custom Radio */}
                          <div className="form-control">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="gender"
                                id="Male"
                                value="MALE"
                                defaultChecked={gender === "MALE"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="MALE"
                              >
                                Male
                              </label>
                            </div>
                          </div>
                          {/* End Custom Radio */}
                          {/* Custom Radio */}
                          <div className="form-control">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="gender"
                                id="Female"
                                value="FEMALE"
                                defaultChecked={gender === "FEMALE"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Female"
                              >
                                Female
                              </label>
                            </div>
                          </div>
                          {/* End Custom Radio */}
                        </div>
                      </div>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="row form-group">
                      <label
                        htmlFor="dobLabel"
                        className="col-sm-3 col-form-label input-label font-weight-bold"
                      >
                        Date of Birth
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          className="form-control"
                          value={doB} // Gắn giá trị ngày sinh từ state
                          onChange={handleDateChange} // Gọi hàm handleDateChange khi người dùng chọn ngày
                        />
                      </div>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="row form-group">
                      <label
                        htmlFor="emailLabel"
                        className="col-sm-3 col-form-label input-label  font-weight-bold"
                      >
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="emailLabel"
                          placeholder="abcxyz123@mail.xyz"
                          aria-label="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="row form-group">
                      <label
                        htmlFor="phoneLabel"
                        className="col-sm-3 col-form-label input-label  font-weight-bold"
                      >
                        Phone
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="js-masked-input form-control"
                          name="phone"
                          id="phoneLabel"
                          placeholder="+x(xxx)xxx-xx-xx"
                          aria-label="+x(xxx)xxx-xx-xx"
                          value={phoneNumber}
                          data-hs-mask-options='{
                         "template": "+0(000)000-00-00"
                       }'
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="row form-group">
                      <label
                        htmlFor="locationLabel"
                        className="col-sm-3 col-form-label input-label font-weight-bold"
                      >
                        Location
                      </label>
                      <div className="col-sm-9">
                        {/* Select */}
                        <select
                          className="form-control"
                          name="location"
                          id="locationLabel"
                          aria-label="Location"
                          value={address}
                          onChange={handleAddressChange}
                        >
                          <option value="">Select Address</option>
                          {listAddress.map((location) => (
                            <option
                              key={location.addressId}
                              value={location.addressId}
                            >
                              {location.city} - {location.addressLine}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* End Form Group */}
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSaveProfile}
                      >
                        {profile ? "Update Profile" : "Createa Profile"}
                      </button>
                    </div>
                  </form>
                  {/* End Form */}
                </div>
                {/* End Body */}
              </div>
              {/* End Card */}

              {/* Sticky Block End Point */}
              <div id="stickyBlockEndPoint" />
            </div>
          </div>
          {/* End Row */}
        </div>
        {/* End Content */}
        {/* Footer */}
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <p className="font-size-sm mb-0">
                © Front.{" "}
                <span className="d-none d-sm-inline-block">
                  2020 Htmlstream.
                </span>
              </p>
            </div>
            <div className="col-auto">
              <div className="d-flex justify-content-end">
                {/* List Dot */}
                <ul className="list-inline list-separator">
                  <li className="list-inline-item">
                    <a className="list-separator-link" href="#">
                      FAQ
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="list-separator-link" href="#">
                      License
                    </a>
                  </li>
                  <li className="list-inline-item">
                    {/* Keyboard Shortcuts Toggle */}
                    <div className="hs-unfold">
                      <a
                        className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                        href="javascript:;"
                        data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                      >
                        <i className="tio-command-key" />
                      </a>
                    </div>
                    {/* End Keyboard Shortcuts Toggle */}
                  </li>
                </ul>
                {/* End List Dot */}
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </main>
    </>
  );
};

export default Settings;
