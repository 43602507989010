import Http from "./Http";
import { getToken } from "../shared/ultils/token";

export const getPlanByField = (fieldId, page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_PLAN_BY_FIELD}?fieldId=${fieldId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getPlanDetail = (planId) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_PLAN_DETAIL}?planId=${planId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};
