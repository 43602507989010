import React from "react";

const OrderDetails = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    {/* Page Header */}
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                href="ecommerce-orders.html"
                                            >
                                                Orders
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Order details
                                        </li>
                                    </ol>
                                </nav>
                                <div className="d-sm-flex align-items-sm-center">
                                    <h1 className="page-header-title">
                                        Order #32543
                                    </h1>
                                    <span className="badge badge-soft-success ml-sm-3">
                                        <span className="legend-indicator bg-success" />
                                        Paid
                                    </span>
                                    <span className="badge badge-soft-info ml-2 ml-sm-3">
                                        <span className="legend-indicator bg-info" />
                                        Fulfilled
                                    </span>
                                    <span className="ml-2 ml-sm-3">
                                        <i className="tio-date-range" /> Aug 17,
                                        2020, 5:48 (ET)
                                    </span>
                                </div>
                                <div className="mt-2">
                                    <a
                                        className="text-body mr-3"
                                        href="javascript:;"
                                        onclick="window.print(); return false;"
                                    >
                                        <i className="tio-print mr-1" /> Print
                                        order
                                    </a>
                                    {/* Unfold */}
                                    <div className="hs-unfold">
                                        <a
                                            className="js-hs-unfold-invoker text-body"
                                            href="javascript:;"
                                            data-hs-unfold-options='{
                 "target": "#moreOptionsDropdown",
                 "type": "css-animation"
               }'
                                        >
                                            More options{" "}
                                            <i className="tio-chevron-down" />
                                        </a>
                                        <div
                                            id="moreOptionsDropdown"
                                            className="hs-unfold-content dropdown-unfold dropdown-menu mt-1"
                                        >
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                <i className="tio-copy dropdown-item-icon" />{" "}
                                                Duplicate
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                <i className="tio-clear dropdown-item-icon" />{" "}
                                                Cancel order
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                <i className="tio-archive dropdown-item-icon" />{" "}
                                                Archive
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                <i className="tio-visible dropdown-item-icon" />{" "}
                                                View order status page
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                <i className="tio-edit dropdown-item-icon" />{" "}
                                                Edit order
                                            </a>
                                        </div>
                                    </div>
                                    {/* End Unfold */}
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle mr-1"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Previous order"
                                >
                                    <i className="tio-arrow-backward" />
                                </a>
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Next order"
                                >
                                    <i className="tio-arrow-forward" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* End Page Header */}
                    <div className="row">
                        <div className="col-lg-8 mb-3 mb-lg-0">
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Order details{" "}
                                        <span className="badge badge-soft-dark rounded-circle ml-1">
                                            4
                                        </span>
                                    </h4>
                                    <a className="link" href="javascript:;">
                                        Edit
                                    </a>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="media">
                                        <div className="avatar avatar-xl mr-3">
                                            <img
                                                className="img-fluid"
                                                src="assets\img\400x400\img26.jpg"
                                                alt="Image Description"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <div className="row">
                                                <div className="col-md-6 mb-3 mb-md-0">
                                                    <a
                                                        className="h5 d-block"
                                                        href="ecommerce-product-details.html"
                                                    >
                                                        Topman shoe in green
                                                    </a>
                                                    <div className="font-size-sm text-body">
                                                        <span>Gender:</span>
                                                        <span className="font-weight-bold">
                                                            Women
                                                        </span>
                                                    </div>
                                                    <div className="font-size-sm text-body">
                                                        <span>Color:</span>
                                                        <span className="font-weight-bold">
                                                            Green
                                                        </span>
                                                    </div>
                                                    <div className="font-size-sm text-body">
                                                        <span>Size:</span>
                                                        <span className="font-weight-bold">
                                                            UK 7
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>$21.00</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>2</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center text-right">
                                                    <h5>$42.00</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                    <hr />
                                    {/* Media */}
                                    <div className="media">
                                        <div className="avatar avatar-xl mr-3">
                                            <img
                                                className="img-fluid"
                                                src="assets\img\400x400\img22.jpg"
                                                alt="Image Description"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <div className="row">
                                                <div className="col-md-6 mb-3 mb-md-0">
                                                    <a
                                                        className="h5 d-block"
                                                        href="ecommerce-product-details.html"
                                                    >
                                                        Office Notebook
                                                    </a>
                                                    <div className="font-size-sm text-body">
                                                        <span>Color:</span>
                                                        <span className="font-weight-bold">
                                                            Gray
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>$9</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>1</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center text-right">
                                                    <h5>$9.00</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                    <hr />
                                    {/* Media */}
                                    <div className="media">
                                        <div className="avatar avatar-xl mr-3">
                                            <img
                                                className="img-fluid"
                                                src="assets\img\400x400\img15.jpg"
                                                alt="Image Description"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <div className="row">
                                                <div className="col-md-6 mb-3 mb-md-0">
                                                    <a
                                                        className="h5 d-block"
                                                        href="ecommerce-product-details.html"
                                                    >
                                                        RayBan sunglasses
                                                    </a>
                                                    <div className="font-size-sm text-body">
                                                        <span>Gender:</span>
                                                        <span className="font-weight-bold">
                                                            Unisex
                                                        </span>
                                                    </div>
                                                    <div className="font-size-sm text-body">
                                                        <span>Color:</span>
                                                        <span className="font-weight-bold">
                                                            Black
                                                        </span>
                                                    </div>
                                                    <div className="font-size-sm text-body">
                                                        <span>Size:</span>
                                                        <span className="font-weight-bold">
                                                            One size
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>$14.00</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center">
                                                    <h5>1</h5>
                                                </div>
                                                <div className="col col-md-2 align-self-center text-right">
                                                    <h5>$14.00</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                    <hr />
                                    <div className="row justify-content-md-end mb-3">
                                        <div className="col-md-8 col-lg-7">
                                            <dl className="row text-sm-right">
                                                <dt className="col-sm-6">
                                                    Subtotal:
                                                </dt>
                                                <dd className="col-sm-6">
                                                    $65.00
                                                </dd>
                                                <dt className="col-sm-6">
                                                    Shipping fee:
                                                </dt>
                                                <dd className="col-sm-6">
                                                    $0.00
                                                </dd>
                                                <dt className="col-sm-6">
                                                    Tax:
                                                </dt>
                                                <dd className="col-sm-6">
                                                    $7.00
                                                </dd>
                                                <dt className="col-sm-6">
                                                    Total:
                                                </dt>
                                                <dd className="col-sm-6">
                                                    $65.00
                                                </dd>
                                                <dt className="col-sm-6">
                                                    Amount paid:
                                                </dt>
                                                <dd className="col-sm-6">
                                                    $65.00
                                                </dd>
                                            </dl>
                                            {/* End Row */}
                                        </div>
                                    </div>
                                    {/* End Row */}
                                </div>
                                {/* End Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Shipping activity
                                        <span className="badge badge-soft-dark ml-1">
                                            <span className="legend-indicator bg-dark" />
                                            Marked as fulfilled
                                        </span>
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Step */}
                                    <ul className="step step-icon-xs">
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <small className="step-divider">
                                                    Wednesday, 19 August
                                                </small>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Delivered
                                                        </a>
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        4:17 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Out for delivery
                                                        </a>
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        2:38 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Package arrived at
                                                            the final delivery
                                                            station
                                                        </a>
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        2:00 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <small className="step-divider">
                                                    Tuesday, 18 August
                                                </small>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Tracking number
                                                        </a>
                                                    </h5>
                                                    <a
                                                        className="link"
                                                        href="#"
                                                    >
                                                        3981241023109293
                                                    </a>
                                                    <p className="font-size-sm mb-0">
                                                        6:29 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Package has
                                                            dispatched
                                                        </a>
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        6:29 AM
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                        {/* Step Item */}
                                        <li className="step-item">
                                            <div className="step-content-wrapper">
                                                <span className="step-icon step-icon-soft-dark step-icon-pseudo" />
                                                <div className="step-content">
                                                    <h5 className="mb-1">
                                                        <a
                                                            className="text-dark"
                                                            href="#"
                                                        >
                                                            Order was placed
                                                        </a>
                                                    </h5>
                                                    <p className="font-size-sm mb-0">
                                                        Order #32543
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        {/* End Step Item */}
                                    </ul>
                                    {/* End Step */}
                                    <small>
                                        Times are shown in the local time zone.
                                    </small>
                                </div>
                                {/* End Body */}
                            </div>
                            {/* End Card */}
                        </div>
                        <div className="col-lg-4">
                            {/* Card */}
                            <div className="card">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Customer
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    <a
                                        className="media align-items-center"
                                        href="ecommerce-customer-details.html"
                                    >
                                        <div className="avatar avatar-circle mr-3">
                                            <img
                                                className="avatar-img"
                                                src="assets\img\160x160\img10.jpg"
                                                alt="Image Description"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <span className="text-body text-hover-primary">
                                                Amanda Harvey
                                            </span>
                                        </div>
                                        <div className="media-body text-right">
                                            <i className="tio-chevron-right text-body" />
                                        </div>
                                    </a>
                                    <hr />
                                    <a
                                        className="media align-items-center"
                                        href="ecommerce-customer-details.html"
                                    >
                                        <div className="icon icon-soft-info icon-circle mr-3">
                                            <i className="tio-shopping-basket-outlined" />
                                        </div>
                                        <div className="media-body">
                                            <span className="text-body text-hover-primary">
                                                7 orders
                                            </span>
                                        </div>
                                        <div className="media-body text-right">
                                            <i className="tio-chevron-right text-body" />
                                        </div>
                                    </a>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Contact info</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    <ul className="list-unstyled list-unstyled-py-2">
                                        <li>
                                            <i className="tio-online mr-2" />
                                            ella@example.com
                                        </li>
                                        <li>
                                            <i className="tio-android-phone-vs mr-2" />
                                            +1 (609) 972-22-22
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Shipping address</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    <span className="d-block">
                                        45 Roker Terrace
                                        <br />
                                        Latheronwheel
                                        <br />
                                        KW5 8NW, London
                                        <br />
                                        UK{" "}
                                        <img
                                            className="avatar avatar-xss avatar-circle ml-1"
                                            src="assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                                            alt="Great Britain Flag"
                                        />
                                    </span>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>Billing address</h5>
                                        <a className="link" href="javascript:;">
                                            Edit
                                        </a>
                                    </div>
                                    <span className="d-block">
                                        45 Roker Terrace
                                        <br />
                                        Latheronwheel
                                        <br />
                                        KW5 8NW, London
                                        <br />
                                        UK{" "}
                                        <img
                                            className="avatar avatar-xss avatar-circle ml-1"
                                            src="assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                                            alt="Great Britain Flag"
                                        />
                                    </span>
                                    <div className="mt-3">
                                        <h5 className="mb-0">Mastercard</h5>
                                        <span className="d-block">
                                            Card Number: ************4291
                                        </span>
                                    </div>
                                </div>
                                {/* End Body */}
                            </div>
                            {/* End Card */}
                        </div>
                    </div>
                    {/* End Row */}
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="d-print-none">
                    <div className="footer">
                        <div className="row justify-content-between align-items-center">
                            <div className="col">
                                <p className="font-size-sm mb-0">
                                    © Front.{" "}
                                    <span className="d-none d-sm-inline-block">
                                        2020 Htmlstream.
                                    </span>
                                </p>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex justify-content-end">
                                    {/* List Dot */}
                                    <ul className="list-inline list-separator">
                                        <li className="list-inline-item">
                                            <a
                                                className="list-separator-link"
                                                href="#"
                                            >
                                                FAQ
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="list-separator-link"
                                                href="#"
                                            >
                                                License
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            {/* Keyboard Shortcuts Toggle */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                          "target": "#keyboardShortcutsSidebar",
                          "type": "css-animation",
                          "animationIn": "fadeInRight",
                          "animationOut": "fadeOutRight",
                          "hasOverlay": true,
                          "smartPositionOff": true
                         }'
                                                >
                                                    <i className="tio-command-key" />
                                                </a>
                                            </div>
                                            {/* End Keyboard Shortcuts Toggle */}
                                        </li>
                                    </ul>
                                    {/* End List Dot */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default OrderDetails;
