import React from "react";

const Profile = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    <div className="row justify-content-lg-center">
                        <div className="col-lg-10">
                            {/* Profile Cover */}
                            <div className="profile-cover">
                                <div className="profile-cover-img-wrapper">
                                    <img
                                        className="profile-cover-img"
                                        src="assets\img\1920x400\img1.jpg"
                                        alt="Image Description"
                                    />
                                </div>
                            </div>
                            {/* End Profile Cover */}
                            {/* Profile Header */}
                            <div className="text-center mb-5">
                                {/* Avatar */}
                                <div className="avatar avatar-xxl avatar-circle avatar-border-lg profile-cover-avatar">
                                    <img
                                        className="avatar-img"
                                        src="assets\img\160x160\img9.jpg"
                                        alt="Image Description"
                                    />
                                    <span className="avatar-status avatar-status-success" />
                                </div>
                                {/* End Avatar */}
                                <h1 className="page-header-title">
                                    Ella Lauda{" "}
                                    <i
                                        className="tio-verified tio-lg text-primary"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Top endorsed"
                                    />
                                </h1>
                                {/* List */}
                                <ul className="list-inline list-inline-m-1">
                                    <li className="list-inline-item">
                                        <i className="tio-city mr-1" />
                                        <span>Htmlstream</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="tio-poi-outlined mr-1" />
                                        <a href="#">San Francisco,</a>
                                        <a href="#">US</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="tio-date-range mr-1" />
                                        <span>Joined March 2017</span>
                                    </li>
                                </ul>
                                {/* End List */}
                            </div>
                            {/* End Profile Header */}
                            {/* Nav */}
                            <div className="js-nav-scroller hs-nav-scroller-horizontal mb-5">
                                <span
                                    className="hs-nav-scroller-arrow-prev"
                                    style={{ display: "none" }}
                                >
                                    <a
                                        className="hs-nav-scroller-arrow-link"
                                        href="javascript:;"
                                    >
                                        <i className="tio-chevron-left" />
                                    </a>
                                </span>
                                <span
                                    className="hs-nav-scroller-arrow-next"
                                    style={{ display: "none" }}
                                >
                                    <a
                                        className="hs-nav-scroller-arrow-link"
                                        href="javascript:;"
                                    >
                                        <i className="tio-chevron-right" />
                                    </a>
                                </span>
                                <ul className="nav nav-tabs align-items-center">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            href="user-profile.html"
                                        >
                                            Profile
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            href="user-profile-teams.html"
                                        >
                                            Teams
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            href="user-profile-projects.html"
                                        >
                                            Projects{" "}
                                            <span className="badge badge-soft-dark rounded-circle ml-1">
                                                3
                                            </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link "
                                            href="user-profile-connections.html"
                                        >
                                            Connections
                                        </a>
                                    </li>
                                    <li className="nav-item ml-auto">
                                        <a
                                            className="btn btn-sm btn-white mr-2"
                                            href="#"
                                        >
                                            <i className="tio-user-add mr-1" />{" "}
                                            Connect
                                        </a>
                                        <a
                                            className="btn btn-icon btn-sm btn-white mr-2"
                                            href="#"
                                        >
                                            <i className="tio-format-points mr-1" />
                                        </a>
                                        {/* Unfold */}
                                        <div className="hs-unfold hs-nav-scroller-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-sm btn-white"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                   "target": "#profileDropdown",
                   "type": "css-animation"
                 }'
                                            >
                                                <i className="tio-more-vertical" />
                                            </a>
                                            <div
                                                id="profileDropdown"
                                                className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                            >
                                                <span className="dropdown-header">
                                                    Settings
                                                </span>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-share dropdown-item-icon" />{" "}
                                                    Share profile
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-blocked dropdown-item-icon" />{" "}
                                                    Block page and profile
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-info-outined dropdown-item-icon" />{" "}
                                                    Suggest edits
                                                </a>
                                                <div className="dropdown-divider" />
                                                <span className="dropdown-header">
                                                    Feedback
                                                </span>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-report-outlined dropdown-item-icon" />{" "}
                                                    Report
                                                </a>
                                            </div>
                                        </div>
                                        {/* End Unfold */}
                                    </li>
                                </ul>
                            </div>
                            {/* End Nav */}
                            <div className="row">
                                <div className="col-lg-4">
                                    {/* Card */}
                                    <div className="card card-body mb-3 mb-lg-5">
                                        <h5>Complete your profile</h5>
                                        {/* Progress */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="progress flex-grow-1">
                                                <div
                                                    className="progress-bar bg-primary"
                                                    role="progressbar"
                                                    style={{ width: "82%" }}
                                                    aria-valuenow={82}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                            <span className="ml-4">82%</span>
                                        </div>
                                        {/* End Progress */}
                                    </div>
                                    {/* End Card */}
                                    {/* Sticky Block Start Point */}
                                    <div id="accountSidebarNav" />
                                    {/* Card */}
                                    <div
                                        className="js-sticky-block card mb-3 mb-lg-5"
                                        data-hs-sticky-block-options='{
                 "parentSelector": "#accountSidebarNav",
                 "breakpoint": "lg",
                 "startPoint": "#accountSidebarNav",
                 "endPoint": "#stickyBlockEndPoint",
                 "stickyOffsetTop": 20
               }'
                                    >
                                        {/* Header */}
                                        <div className="card-header">
                                            <h5 className="card-header-title">
                                                Profile
                                            </h5>
                                        </div>
                                        {/* End Header */}
                                        {/* Body */}
                                        <div className="card-body">
                                            <ul className="list-unstyled list-unstyled-py-3 text-dark mb-3">
                                                <li className="py-0">
                                                    <small className="card-subtitle">
                                                        About
                                                    </small>
                                                </li>
                                                <li>
                                                    <i className="tio-user-outlined nav-icon" />
                                                    Ella Lauda
                                                </li>
                                                <li>
                                                    <i className="tio-briefcase-outlined nav-icon" />
                                                    No department
                                                </li>
                                                <li>
                                                    <i className="tio-city nav-icon" />
                                                    Htmlstream
                                                </li>
                                                <li className="pt-2 pb-0">
                                                    <small className="card-subtitle">
                                                        Contacts
                                                    </small>
                                                </li>
                                                <li>
                                                    <i className="tio-online nav-icon" />
                                                    ella@example.com
                                                </li>
                                                <li>
                                                    <i className="tio-android-phone-vs nav-icon" />
                                                    +1 (609) 972-22-22
                                                </li>
                                                <li className="pt-2 pb-0">
                                                    <small className="card-subtitle">
                                                        Teams
                                                    </small>
                                                </li>
                                                <li>
                                                    <i className="tio-group-equal nav-icon" />
                                                    Member of 7 teams
                                                </li>
                                                <li>
                                                    <i className="tio-briefcase-outlined nav-icon" />
                                                    Working on 8 projects
                                                </li>
                                            </ul>
                                        </div>
                                        {/* End Body */}
                                    </div>
                                    {/* End Card */}
                                </div>
                                <div className="col-lg-8">
                                    {/* Card */}
                                    <div className="card mb-3 mb-lg-5">
                                        {/* Header */}
                                        <div className="card-header">
                                            <h5 className="card-header-title">
                                                Activity stream
                                            </h5>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#contentActivityStreamDropdown",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-vertical" />
                                                </a>
                                                <div
                                                    id="contentActivityStreamDropdown"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-share dropdown-item-icon" />{" "}
                                                        Share connections
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-info-outined dropdown-item-icon" />{" "}
                                                        Suggest edits
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-chat-outlined dropdown-item-icon" />{" "}
                                                        Report
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </div>
                                        {/* End Header */}
                                        {/* Body */}
                                        <div
                                            className="card-body card-body-height"
                                            style={{ height: "30rem" }}
                                        >
                                            {/* Step */}
                                            <ul className="step step-icon-xs">
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Task report
                                                                    - uploaded
                                                                    weekly
                                                                    reports
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Added 3 files to
                                                                task{" "}
                                                                <a
                                                                    className="text-uppercase"
                                                                    href="#"
                                                                >
                                                                    <i className="tio-folder-bookmarked" />{" "}
                                                                    Fd-7
                                                                </a>
                                                            </p>
                                                            <ul className="list-group">
                                                                {/* List Item */}
                                                                <li className="list-group-item list-group-item-light">
                                                                    <div className="row gx-1">
                                                                        <div className="col">
                                                                            <div className="media">
                                                                                <span className="mt-1 mr-2">
                                                                                    <img
                                                                                        className="avatar avatar-xs"
                                                                                        src="assets\svg\brands\excel.svg"
                                                                                        alt="Image Description"
                                                                                    />
                                                                                </span>
                                                                                <div className="media-body text-truncate">
                                                                                    <span
                                                                                        className="d-block font-size-sm text-dark text-truncate"
                                                                                        title="weekly-reports.xls"
                                                                                    >
                                                                                        weekly-reports.xls
                                                                                    </span>
                                                                                    <small className="d-block text-muted">
                                                                                        12kb
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="media">
                                                                                <span className="mt-1 mr-2">
                                                                                    <img
                                                                                        className="avatar avatar-xs"
                                                                                        src="assets\svg\brands\word.svg"
                                                                                        alt="Image Description"
                                                                                    />
                                                                                </span>
                                                                                <div className="media-body text-truncate">
                                                                                    <span
                                                                                        className="d-block font-size-sm text-dark text-truncate"
                                                                                        title="weekly-reports.xls"
                                                                                    >
                                                                                        weekly-reports.xls
                                                                                    </span>
                                                                                    <small className="d-block text-muted">
                                                                                        4kb
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="media">
                                                                                <span className="mt-1 mr-2">
                                                                                    <img
                                                                                        className="avatar avatar-xs"
                                                                                        src="assets\svg\brands\word.svg"
                                                                                        alt="Image Description"
                                                                                    />
                                                                                </span>
                                                                                <div className="media-body text-truncate">
                                                                                    <span
                                                                                        className="d-block font-size-sm text-dark text-truncate"
                                                                                        title="monthly-reports.xls"
                                                                                    >
                                                                                        monthly-reports.xls
                                                                                    </span>
                                                                                    <small className="d-block text-muted">
                                                                                        8kb
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                {/* End List Item */}
                                                            </ul>
                                                            <small className="text-muted text-uppercase">
                                                                Now
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Project
                                                                    status
                                                                    updated
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Marked{" "}
                                                                <a
                                                                    className="text-uppercase"
                                                                    href="#"
                                                                >
                                                                    <i className="tio-folder-bookmarked" />{" "}
                                                                    Fr-6
                                                                </a>{" "}
                                                                as{" "}
                                                                <span className="badge badge-soft-success badge-pill">
                                                                    <span className="legend-indicator bg-success" />
                                                                    "Completed"
                                                                </span>
                                                            </p>
                                                            <small className="text-muted text-uppercase">
                                                                Today
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    New card
                                                                    styles added
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Added 5 card to{" "}
                                                                <a href="#">
                                                                    Payments
                                                                </a>
                                                            </p>
                                                            <ul className="list-group">
                                                                {/* List Item */}
                                                                <li className="list-group-item list-group-item-light">
                                                                    <div className="row gx-1">
                                                                        <div className="col">
                                                                            <img
                                                                                className="img-fluid rounded ie-card-img"
                                                                                src="assets\svg\illustrations\card-1.svg"
                                                                                alt="Image Description"
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <img
                                                                                className="img-fluid rounded ie-card-img"
                                                                                src="assets\svg\illustrations\card-2.svg"
                                                                                alt="Image Description"
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <img
                                                                                className="img-fluid rounded ie-card-img"
                                                                                src="assets\svg\illustrations\card-3.svg"
                                                                                alt="Image Description"
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <img
                                                                                className="img-fluid rounded ie-card-img"
                                                                                src="assets\svg\illustrations\card-4.svg"
                                                                                alt="Image Description"
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <img
                                                                                className="img-fluid rounded ie-card-img"
                                                                                src="assets\svg\illustrations\card-5.svg"
                                                                                alt="Image Description"
                                                                            />
                                                                        </div>
                                                                        <div className="col-auto align-self-center">
                                                                            <div className="text-center">
                                                                                <a href="#">
                                                                                    +2
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                {/* List Item */}
                                                            </ul>
                                                            <small className="text-muted text-uppercase">
                                                                May 12
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Dean added a
                                                                    new team
                                                                    member
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Added a new
                                                                member to Front
                                                                Dashboard
                                                            </p>
                                                            <small className="text-muted text-uppercase">
                                                                May 15
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Project
                                                                    status
                                                                    updated
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Marked{" "}
                                                                <a
                                                                    className="text-uppercase"
                                                                    href="#"
                                                                >
                                                                    <i className="tio-folder-bookmarked" />{" "}
                                                                    Fr-3
                                                                </a>{" "}
                                                                as{" "}
                                                                <span className="badge badge-soft-primary badge-pill">
                                                                    <span className="legend-indicator bg-primary" />
                                                                    "In
                                                                    progress"
                                                                </span>
                                                            </p>
                                                            <small className="text-muted text-uppercase">
                                                                Apr 29
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li className="step-item">
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Achievements
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Earned a "Top
                                                                endorsed"{" "}
                                                                <i className="tio-verified text-primary" />{" "}
                                                                badge
                                                            </p>
                                                            <small className="text-muted text-uppercase">
                                                                Apr 06
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                                {/* Step Item */}
                                                <li
                                                    id="collapseActivitySection"
                                                    className="step-item collapse"
                                                >
                                                    <div className="step-content-wrapper">
                                                        <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
                                                        <div className="step-content">
                                                            <h5 className="mb-1">
                                                                <a
                                                                    className="text-dark"
                                                                    href="#"
                                                                >
                                                                    Project
                                                                    status
                                                                    updated
                                                                </a>
                                                            </h5>
                                                            <p className="font-size-sm mb-1">
                                                                Updated{" "}
                                                                <a
                                                                    className="text-uppercase"
                                                                    href="#"
                                                                >
                                                                    <i className="tio-folder-bookmarked" />{" "}
                                                                    Fr-3
                                                                </a>{" "}
                                                                as{" "}
                                                                <span className="badge badge-soft-secondary badge-pill">
                                                                    <span className="legend-indicator bg-secondary" />
                                                                    "To do"
                                                                </span>
                                                            </p>
                                                            <small className="text-muted text-uppercase">
                                                                Feb 10
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* End Step Item */}
                                            </ul>
                                            {/* End Step */}
                                        </div>
                                        {/* End Body */}
                                        {/* Footer */}
                                        <div className="card-footer">
                                            <a
                                                className="btn btn-sm btn-ghost-secondary"
                                                data-toggle="collapse"
                                                href="#collapseActivitySection"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseActivitySection"
                                            >
                                                <span className="btn-toggle-default">
                                                    <i className="tio-chevron-down mr-1" />{" "}
                                                    View more
                                                </span>
                                                <span className="btn-toggle-toggled">
                                                    <i className="tio-chevron-up mr-1" />{" "}
                                                    View less
                                                </span>
                                            </a>
                                        </div>
                                        {/* End Footer */}
                                    </div>
                                    {/* End Card */}
                                    <div className="row">
                                        <div className="col-sm-6 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card h-100">
                                                {/* Header */}
                                                <div className="card-header">
                                                    <h5 className="card-header-title">
                                                        Connections
                                                    </h5>
                                                </div>
                                                {/* End Header */}
                                                {/* Body */}
                                                <div className="card-body">
                                                    <ul className="list-unstyled list-unstyled-py-4 mb-0">
                                                        {/* List Item */}
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <a
                                                                    className="d-flex align-items-center mr-2"
                                                                    href="#"
                                                                >
                                                                    <div className="avatar avatar-sm avatar-soft-primary avatar-circle">
                                                                        <span className="avatar-initials">
                                                                            R
                                                                        </span>
                                                                        <span className="avatar-status avatar-sm-status avatar-status-warning" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <h5 className="text-hover-primary mb-0">
                                                                            Rachel
                                                                            Doe
                                                                        </h5>
                                                                        <span className="font-size-sm text-body">
                                                                            25
                                                                            connections
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                                <div className="ml-auto">
                                                                    {/* Checkbox */}
                                                                    <div className="custom-control custom-checkbox-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="connectionsCheckbox1"
                                                                            className="custom-control-input custom-checkbox-switch-input"
                                                                            defaultChecked
                                                                        />
                                                                        <label
                                                                            className="custom-checkbox-switch-label btn-icon btn-xs rounded-circle"
                                                                            htmlFor="connectionsCheckbox1"
                                                                        >
                                                                            <span className="custom-checkbox-switch-default">
                                                                                <i className="tio-user-add" />
                                                                            </span>
                                                                            <span className="custom-checkbox-switch-active">
                                                                                <i className="tio-done" />
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    {/* End Checkbox */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <a
                                                                    className="d-flex align-items-center mr-2"
                                                                    href="#"
                                                                >
                                                                    <div className="avatar avatar-sm avatar-circle">
                                                                        <img
                                                                            className="avatar-img"
                                                                            src="assets\img\160x160\img8.jpg"
                                                                            alt="Image Description"
                                                                        />
                                                                        <span className="avatar-status avatar-sm-status avatar-status-success" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <h5 className="text-hover-primary mb-0">
                                                                            Isabella
                                                                            Finley
                                                                        </h5>
                                                                        <span className="font-size-sm text-body">
                                                                            79
                                                                            connections
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                                <div className="ml-auto">
                                                                    {/* Checkbox */}
                                                                    <div className="custom-control custom-checkbox-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="connectionsCheckbox2"
                                                                            className="custom-control-input custom-checkbox-switch-input"
                                                                        />
                                                                        <label
                                                                            className="custom-checkbox-switch-label btn-icon btn-xs rounded-circle"
                                                                            htmlFor="connectionsCheckbox2"
                                                                        >
                                                                            <span className="custom-checkbox-switch-default">
                                                                                <i className="tio-user-add" />
                                                                            </span>
                                                                            <span className="custom-checkbox-switch-active">
                                                                                <i className="tio-done" />
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    {/* End Checkbox */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <a
                                                                    className="d-flex align-items-center mr-2"
                                                                    href="#"
                                                                >
                                                                    <div className="avatar avatar-sm avatar-circle">
                                                                        <img
                                                                            className="avatar-img"
                                                                            src="assets\img\160x160\img3.jpg"
                                                                            alt="Image Description"
                                                                        />
                                                                        <span className="avatar-status avatar-sm-status avatar-status-warning" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <h5 className="text-hover-primary mb-0">
                                                                            David
                                                                            Harrison
                                                                        </h5>
                                                                        <span className="font-size-sm text-body">
                                                                            0
                                                                            connections
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                                <div className="ml-auto">
                                                                    {/* Checkbox */}
                                                                    <div className="custom-control custom-checkbox-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="connectionsCheckbox3"
                                                                            className="custom-control-input custom-checkbox-switch-input"
                                                                            defaultChecked
                                                                        />
                                                                        <label
                                                                            className="custom-checkbox-switch-label btn-icon btn-xs rounded-circle"
                                                                            htmlFor="connectionsCheckbox3"
                                                                        >
                                                                            <span className="custom-checkbox-switch-default">
                                                                                <i className="tio-user-add" />
                                                                            </span>
                                                                            <span className="custom-checkbox-switch-active">
                                                                                <i className="tio-done" />
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    {/* End Checkbox */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <a
                                                                    className="d-flex align-items-center mr-2"
                                                                    href="#"
                                                                >
                                                                    <div className="avatar avatar-sm avatar-circle">
                                                                        <img
                                                                            className="avatar-img"
                                                                            src="assets\img\160x160\img6.jpg"
                                                                            alt="Image Description"
                                                                        />
                                                                        <span className="avatar-status avatar-sm-status avatar-status-danger" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <h5 className="text-hover-primary mb-0">
                                                                            Costa
                                                                            Quinn
                                                                        </h5>
                                                                        <span className="font-size-sm text-body">
                                                                            9
                                                                            connections
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                                <div className="ml-auto">
                                                                    {/* Checkbox */}
                                                                    <div className="custom-control custom-checkbox-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="connectionsCheckbox4"
                                                                            className="custom-control-input custom-checkbox-switch-input"
                                                                        />
                                                                        <label
                                                                            className="custom-checkbox-switch-label btn-icon btn-xs rounded-circle"
                                                                            htmlFor="connectionsCheckbox4"
                                                                        >
                                                                            <span className="custom-checkbox-switch-default">
                                                                                <i className="tio-user-add" />
                                                                            </span>
                                                                            <span className="custom-checkbox-switch-active">
                                                                                <i className="tio-done" />
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    {/* End Checkbox */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {/* End List Item */}
                                                    </ul>
                                                </div>
                                                {/* End Body */}
                                                {/* Footer */}
                                                <a
                                                    className="card-footer text-center"
                                                    href="user-profile-connections.html"
                                                >
                                                    View all connections{" "}
                                                    <i className="tio-chevron-right" />
                                                </a>
                                                {/* End Footer */}
                                            </div>
                                            {/* End Card */}
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card h-100">
                                                {/* Header */}
                                                <div className="card-header">
                                                    <h5 className="card-header-title">
                                                        Teams
                                                    </h5>
                                                </div>
                                                {/* End Header */}
                                                {/* Body */}
                                                <div className="card-body">
                                                    <ul className="nav card-nav card-nav-vertical nav-pills">
                                                        {/* List Item */}
                                                        <li>
                                                            <a
                                                                className="nav-link media"
                                                                href="#"
                                                            >
                                                                <i className="tio-group-senior nav-icon text-dark" />
                                                                <span className="media-body">
                                                                    <span className="d-block text-dark">
                                                                        #digitalmarketing
                                                                    </span>
                                                                    <small className="d-block text-muted">
                                                                        8
                                                                        members
                                                                    </small>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <a
                                                                className="nav-link media"
                                                                href="#"
                                                            >
                                                                <i className="tio-group-senior nav-icon text-dark" />
                                                                <span className="media-body">
                                                                    <span className="d-block text-dark">
                                                                        #ethereum
                                                                    </span>
                                                                    <small className="d-block text-muted">
                                                                        14
                                                                        members
                                                                    </small>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <a
                                                                className="nav-link media"
                                                                href="#"
                                                            >
                                                                <i className="tio-group-senior nav-icon text-dark" />
                                                                <span className="media-body">
                                                                    <span className="d-block text-dark">
                                                                        #conference
                                                                    </span>
                                                                    <small className="d-block text-muted">
                                                                        3
                                                                        members
                                                                    </small>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <a
                                                                className="nav-link media"
                                                                href="#"
                                                            >
                                                                <i className="tio-group-senior nav-icon text-dark" />
                                                                <span className="media-body">
                                                                    <span className="d-block text-dark">
                                                                        #supportteam
                                                                    </span>
                                                                    <small className="d-block text-muted">
                                                                        3
                                                                        members
                                                                    </small>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        {/* End List Item */}
                                                        {/* List Item */}
                                                        <li>
                                                            <a
                                                                className="nav-link media"
                                                                href="#"
                                                            >
                                                                <i className="tio-group-senior nav-icon text-dark" />
                                                                <span className="media-body">
                                                                    <span className="d-block text-dark">
                                                                        #invoices
                                                                    </span>
                                                                    <small className="d-block text-muted">
                                                                        3
                                                                        members
                                                                    </small>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        {/* End List Item */}
                                                    </ul>
                                                </div>
                                                {/* End Body */}
                                                {/* Footer */}
                                                <a
                                                    className="card-footer text-center"
                                                    href="user-profile-teams.html"
                                                >
                                                    View all teams{" "}
                                                    <i className="tio-chevron-right" />
                                                </a>
                                                {/* End Footer */}
                                            </div>
                                            {/* End Card */}
                                        </div>
                                    </div>
                                    {/* End Row */}
                                    {/* Card */}
                                    <div className="card mb-3 mb-lg-5">
                                        {/* Header */}
                                        <div className="card-header">
                                            <h5 className="card-header-title">
                                                Projects
                                            </h5>
                                            {/* Unfold */}
                                            <div className="hs-unfold">
                                                <a
                                                    className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                                    href="javascript:;"
                                                    data-hs-unfold-options='{
                     "target": "#projectReportDropdown",
                     "type": "css-animation"
                   }'
                                                >
                                                    <i className="tio-more-vertical" />
                                                </a>
                                                <div
                                                    id="projectReportDropdown"
                                                    className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                                >
                                                    <span className="dropdown-header">
                                                        Settings
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-share dropdown-item-icon" />{" "}
                                                        Share connections
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-info-outined dropdown-item-icon" />{" "}
                                                        Suggest edits
                                                    </a>
                                                    <div className="dropdown-divider" />
                                                    <span className="dropdown-header">
                                                        Feedback
                                                    </span>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        <i className="tio-chat-outlined dropdown-item-icon" />{" "}
                                                        Report
                                                    </a>
                                                </div>
                                            </div>
                                            {/* End Unfold */}
                                        </div>
                                        {/* End Header */}
                                        {/* Table */}
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Project</th>
                                                        <th
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            Progress
                                                        </th>
                                                        <th className="table-column-right-aligned">
                                                            Hours spent
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <span className="avatar avatar-xs avatar-soft-dark avatar-circle">
                                                                    <span className="avatar-initials">
                                                                        U
                                                                    </span>
                                                                </span>
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        UI/UX
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        2 hours
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    0%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "0%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            0
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            4:25
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <img
                                                                    className="avatar avatar-xs"
                                                                    src="assets\svg\brands\spec.svg"
                                                                    alt="Image Description"
                                                                />
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        Get a
                                                                        complete
                                                                        audit
                                                                        store
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        1 day
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    45%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "45%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            45
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            18:42
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <img
                                                                    className="avatar avatar-xs"
                                                                    src="assets\svg\brands\capsule.svg"
                                                                    alt="Image Description"
                                                                />
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        Build
                                                                        stronger
                                                                        customer
                                                                        relationships
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        2 days
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    59%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "59%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            59
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            9:01
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <img
                                                                    className="avatar avatar-xs"
                                                                    src="assets\svg\brands\mailchimp.svg"
                                                                    alt="Image Description"
                                                                />
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        Update
                                                                        subscription
                                                                        method
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        2 days
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    57%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar bg-success"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "57%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            57
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            0:37
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <img
                                                                    className="avatar avatar-xs"
                                                                    src="assets\svg\brands\figma.svg"
                                                                    alt="Image Description"
                                                                />
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        Create a
                                                                        new
                                                                        theme
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        1 week
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    100%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar bg-success"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            100
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            24:12
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <span className="avatar avatar-xs avatar-soft-info avatar-circle">
                                                                    <span className="avatar-initials">
                                                                        I
                                                                    </span>
                                                                </span>
                                                                <div className="ml-3">
                                                                    <h5 className="mb-0">
                                                                        Improve
                                                                        social
                                                                        banners
                                                                    </h5>
                                                                    <small>
                                                                        Updated
                                                                        1 week
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-3">
                                                                    0%
                                                                </span>
                                                                <div className="progress table-progress">
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: "0%",
                                                                        }}
                                                                        aria-valuenow={
                                                                            0
                                                                        }
                                                                        aria-valuemin={
                                                                            0
                                                                        }
                                                                        aria-valuemax={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="table-column-right-aligned">
                                                            8:08
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* End Table */}
                                        {/* Footer */}
                                        <a
                                            className="card-footer text-center"
                                            href="projects.html"
                                        >
                                            View all projects{" "}
                                            <i className="tio-chevron-right" />
                                        </a>
                                        {/* End Footer */}
                                    </div>
                                    {/* End Card */}
                                    {/* Sticky Block End Point */}
                                    <div id="stickyBlockEndPoint" />
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                    </div>
                    {/* End Row */}
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="footer">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <p className="font-size-sm mb-0">
                                © Front.{" "}
                                <span className="d-none d-sm-inline-block">
                                    2020 Htmlstream.
                                </span>
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-end">
                                {/* List Dot */}
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            License
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* Keyboard Shortcuts Toggle */}
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                                            >
                                                <i className="tio-command-key" />
                                            </a>
                                        </div>
                                        {/* End Keyboard Shortcuts Toggle */}
                                    </li>
                                </ul>
                                {/* End List Dot */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default Profile;
