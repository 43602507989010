import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUserInfoById, editUserInfo } from "../services/AdminAPI"; // Đảm bảo đã định nghĩa hàm này trong dịch vụ

const AdminEditUserInfo = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: "",
    role: "",
    password: "",
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfoById(userId);
        if (response.data && response.data.data) {
          setUserInfo(response.data.data);
          setFormData({
            username: response.data.data.username,
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            email: response.data.data.email,
            phoneNumber: response.data.data.phoneNumber,
            gender: response.data.data.gender,
            dateOfBirth: response.data.data.dateOfBirth.split("T")[0],
            role: response.data.data.role || "",
            password: "",
          });
        } else {
          setError("User not found.");
        }
      } catch (err) {
        console.error("Error fetching user info:", err);
        if (err.response) {
          if (err.response.status === 403) {
            setError("Admin only."); // Thông báo khi không có quyền
          } else {
            setError(err.response?.data?.message || "Failed to load user info.");
          }
        } else {
          setError("Failed to load user info.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password, ...otherData } = formData; // Tách username và password ra
    try {
      await editUserInfo(userId, {
        ...otherData,
        account: {
          username: username || null, // Nếu không muốn thay đổi username, có thể để null
          password: password || null, // Nếu không muốn thay đổi password, có thể để null
          role: otherData.role.toLowerCase() === "role_admin" ? "admin" : "user", // Chuyển đổi role thành chữ thường
        },
      });
      navigate(`/admin/userinfo/${userId}`); // Chuyển hướng sau khi cập nhật thành công
    } catch (err) {
      console.error("Error updating user info:", err);
      setError(err.response?.data?.message || "Failed to update user info."); // Hiển thị thông báo lỗi cụ thể
    }
  };

  return (
    <div className="container mt-5">
      <h3>Edit User Info</h3>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-danger">{error}</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Gender</label>
            <input
              type="text"
              className="form-control"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Role</label>
            <select
              className="form-select"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Role</option>
              <option value="ROLE_ADMIN">ROLE_ADMIN</option>
              <option value="ROLE_USER">ROLE_USER</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Leave blank to keep the current password"
            />
          </div>
          <button type="submit" className="btn btn-primary">Update User Info</button>
        </form>
      )}
    </div>
  );
};

export default AdminEditUserInfo;