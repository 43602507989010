import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTreeDetailsByTreeId } from '../services/areas';

const RubberTreeDetails = () => {
  const { treeId } = useParams();
  const [treeDetails, setTreeDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTreeDetails = async () => {
      try {
        const response = await getTreeDetailsByTreeId(treeId);
        if (response.data.length > 0) {
          setTreeDetails(response.data[0]);
        } else {
          setTreeDetails(null);
        }
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching tree details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTreeDetails();
  }, [treeId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  };

  return (
    <div className="container mt-5 d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
      <div className="col-md-10 col-lg-8">
        <h2 className="text-center mb-4" style={{ fontSize: '2rem' }}>Rubber Tree Details</h2>
        {loading ? (
          <p className="text-center" style={{ fontSize: '1.25rem' }}>Loading...</p>
        ) : treeDetails ? (
          <div
            className="card shadow-lg hover-card"
            style={{
              padding: '2rem',
              fontSize: '1.5rem',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
          >
            <div className="card-body">
              <h5 className="card-title mb-4 text-center" style={{ fontSize: '2rem' }}>Tree ID: {treeDetails.treeId}</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="card-text mb-3"><strong>Top Height:</strong> {treeDetails.topHeight} meters</div>
                  <div className="card-text mb-3"><strong>Crown Height:</strong> {treeDetails.crownHeight} meters</div>
                  <div className="card-text mb-3"><strong>Diameter:</strong> {treeDetails.diamEter} cm</div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3"><strong>Circumference:</strong> {treeDetails.cirumFerence} cm</div>
                  <div className="card-text mb-3">
                    <strong>Check Day:</strong> <div>{formatDate(treeDetails.checkDate)}</div>
                  </div>
                  <div className="card-text mb-3">
                    <strong>Check Time:</strong> <div>{formatTime(treeDetails.checkDate)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center" style={{ fontSize: '1.25rem' }}>Tree details not found.</p>
        )}
      </div>
    </div>
  );
};

export default RubberTreeDetails;
