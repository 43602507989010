import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTask, getTaskByPlan, getTaskByRobot } from "../services/task";

const TaskList = () => {
  const { planId } = useParams();
  const { robotId } = useParams();
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (planId) {
      const fetchTasksByPlan = async () => {
        try {
          const response = await getTaskByPlan(planId, page);
          setTasks(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTasksByPlan();
    } else if (robotId) {
      const fetchTasksByRobot = async () => {
        try {
          const response = await getTaskByRobot(robotId, page);
          setTasks(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTasksByRobot();
    } else {
      const fetchTasks = async () => {
        try {
          const response = await getAllTask(page);
          setTasks(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTasks();
    }
  }, [planId, robotId, page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleClickFilter = () => {
    if (filter === "planId") navigate(`/task/plan/${searchValue}`);
    if (filter === "robotId") navigate(`/task/robot/${searchValue}`);
    if (filter === "") navigate(`/task`);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Tasks</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          {/* Search Bar */}
          <div className="row mb-4 align-items-center justify-content-center">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Robot ID or Plan ID"
                value={searchValue}
                onChange={handleSearchValue}
              />
            </div>
            <div className="col-md-3">
              {/* Select */}
              <select
                className="form-control"
                name="fitler"
                id="filterLabel"
                value={filter}
                onChange={handleFilter}
              >
                <option value="">All</option>
                <option value="planId">Plan</option>
                <option value="robotId">Robot</option>
              </select>
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-primary font-weight-bolder"
                onClick={handleClickFilter}
              >
                Search
              </button>
            </div>
          </div>

          <div className="row">
            {tasks.map((task) => (
              <div key={task.taskId} className="col-lg-4 col-md-6 mb-4">
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <h4 className="card-header-title">
                      Task Id: {task.TaskId}
                    </h4>
                    <h5 className="card-title">Robot Id: {task.robotId}</h5>
                    <h5 className="card-title">Plan Id: {task.planId}</h5>
                    <p className="card-text">
                      Action Detail: {task.actionDetail}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? "active" : ""
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TaskList;
