import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllRobot } from "../services/robot.js";

const RobotList = () => {
  const [robots, setRobots] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchRobot = async () => {
      try {
        const response = await getAllRobot(page);
        setRobots(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRobot();
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRobotClick = (robotId) => {
    navigate(`/robot-detail/${robotId}`);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Robots</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="row">
            {robots.map((robot) => (
              <div
                key={robot.id}
                className="col-lg-4 col-md-6 mb-4"
                onClick={() => handleRobotClick(robot.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <h4 className="card-header-title">Robot Id: {robot.id}</h4>
                    <h5 className="card-title">Type: {robot.typeRobot}</h5>
                    <h5 className="card-title">Model: {robot.model}</h5>
                    <h5 className="card-title">
                      Status:{" "}
                      {robot.status ? (
                        <p className="text-success">Online</p>
                      ) : (
                        <p className="text-danger">Offline</p>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? "active" : ""
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RobotList;
