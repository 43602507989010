import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUserInfoById } from "../services/AdminAPI"; // Đảm bảo đã định nghĩa hàm này trong dịch vụ

const AdminUserInfo = () => {
  const { userId } = useParams(); // Lấy userId từ URL
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Khai báo useNavigate để điều hướng

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfoById(userId); // Gọi API để lấy thông tin người dùng
        if (response.data && response.data.data) {
          setUserInfo(response.data.data);
        } else {
          setError("User not found.");
        }
      } catch (err) {
        console.error("Error fetching user info:", err);
        if (err.response) {
          if (err.response.status === 403) {
            setError("Admin only."); // Thông báo khi không có quyền
          } else {
            setError(err.response?.data?.message || "Failed to load user info.");
          }
        } else {
          setError("Failed to load user info.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleEditUser = () => {
    navigate(`/admin/edit/${userId}`); // Điều hướng đến trang chỉnh sửa thông tin người dùng
  };

  return (
    <div className="container mt-5">
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : error ? (
        <p className="text-center text-danger">{error}</p>
      ) : userInfo ? (
        <div>
          <h3 className="text-center mb-4">User Info</h3>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">User ID: {userInfo.userInfoId}</h5>
              <p className="card-text"><strong>Username:</strong> {userInfo.username}</p>
              <p className="card-text"><strong>First Name:</strong> {userInfo.firstName}</p>
              <p className="card-text"><strong>Last Name:</strong> {userInfo.lastName}</p>
              <p className="card-text"><strong>Email:</strong> {userInfo.email}</p>
              <p className="card-text"><strong>Phone Number:</strong> {userInfo.phoneNumber}</p>
              <p className="card-text"><strong>Gender:</strong> {userInfo.gender}</p>
              <p className="card-text"><strong>Date of Birth:</strong> {new Date(userInfo.dateOfBirth).toLocaleDateString()}</p>
              <p className="card-text"><strong>Address ID:</strong> {userInfo.addressId}</p>
              <p className="card-text"><strong>Account ID:</strong> {userInfo.accountId}</p>
              <p className="card-text"><strong>Created Date:</strong> {new Date(userInfo.createdDate).toLocaleString()}</p>
              <p className="card-text"><strong>Updated Date:</strong> {new Date(userInfo.updatedDate).toLocaleString()}</p>
              <button className="btn btn-primary" onClick={handleEditUser}>
                Edit User Info
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center">User not found.</p>
      )}
    </div>
  );
};

export default AdminUserInfo;