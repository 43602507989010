import React from "react";

const ProductDetails = () => {
    return (
        <>
            <main id="content" role="main" className="main">
                {/* Content */}
                <div className="">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                href="ecommerce-products.html"
                                            >
                                                Products
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Product details
                                        </li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title">
                                    Tiro track jacket
                                </h1>
                                <div className="mt-2">
                                    <a
                                        className="text-body mr-3"
                                        href="javascript:;"
                                    >
                                        <i className="tio-copy mr-1" />{" "}
                                        Duplicate
                                    </a>
                                    <a
                                        className="text-body"
                                        href="javascript:;"
                                    >
                                        <i className="tio-visible-outlined mr-1" />{" "}
                                        Preview
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle mr-1"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Previous product"
                                >
                                    <i className="tio-arrow-backward" />
                                </a>
                                <a
                                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                                    href="#"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Next product"
                                >
                                    <i className="tio-arrow-forward" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* End Page Header */}
                    <div className="row">
                        <div className="col-lg-8">
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Product information
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Form Group */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="productNameLabel"
                                            className="input-label"
                                        >
                                            Name{" "}
                                            <i
                                                className="tio-help-outlined text-body ml-1"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Products are the goods or services you sell."
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="productName"
                                            id="productNameLabel"
                                            placeholder="Shirt, t-shirts, etc."
                                            aria-label="Shirt, t-shirts, etc."
                                            defaultValue="Tiro track jacket"
                                        />
                                    </div>
                                    {/* End Form Group */}
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/* Form Group */}
                                            <div className="form-group">
                                                <label
                                                    htmlFor="SKULabel"
                                                    className="input-label"
                                                >
                                                    SKU
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="SKU"
                                                    id="SKULabel"
                                                    placeholder="eg. 348121032"
                                                    aria-label="eg. 348121032"
                                                    defaultValue={124617209}
                                                />
                                            </div>
                                            {/* End Form Group */}
                                        </div>
                                        <div className="col-sm-6">
                                            {/* Form Group */}
                                            <div className="form-group">
                                                <label
                                                    htmlFor="weightLabel"
                                                    className="input-label"
                                                >
                                                    Weight
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="weightName"
                                                        id="weightLabel"
                                                        placeholder={0.0}
                                                        aria-label={0.0}
                                                        defaultValue="0.2"
                                                    />
                                                    <div className="input-group-append">
                                                        {/* Select */}
                                                        <div
                                                            id="priceSelect"
                                                            className="select2-custom"
                                                        >
                                                            <select
                                                                className="js-select2-custom custom-select"
                                                                size={1}
                                                                style={{
                                                                    opacity: 0,
                                                                }}
                                                                data-hs-select2-options='{
                                "dropdownParent": "#priceSelect",
                                "dropdownAutoWidth": true,
                                "width": true
                              }'
                                                            >
                                                                <option value="lb">
                                                                    lb
                                                                </option>
                                                                <option value="oz">
                                                                    oz
                                                                </option>
                                                                <option
                                                                    value="kg"
                                                                    selected
                                                                >
                                                                    kg
                                                                </option>
                                                                <option value="g">
                                                                    g
                                                                </option>
                                                            </select>
                                                        </div>
                                                        {/* End Select */}
                                                    </div>
                                                </div>
                                                <small className="form-text">
                                                    Used to calculate shipping
                                                    rates at checkout and label
                                                    prices during fulfillment.
                                                </small>
                                            </div>
                                            {/* End Form Group */}
                                        </div>
                                    </div>
                                    {/* End Row */}
                                    <label className="input-label">
                                        Description{" "}
                                        <span className="input-label-secondary">
                                            (Optional)
                                        </span>
                                    </label>
                                    {/* Quill */}
                                    <div className="quill-custom">
                                        <div
                                            className="js-quill"
                                            style={{ minHeight: "15rem" }}
                                            data-hs-quill-options='{
                    "placeholder": "Type your description..."
                   }'
                                        >
                                            <p>
                                                Train hard. Stay dry. This
                                                soccer jacket is made of soft,
                                                sweat-wicking fabric that keeps
                                                you moving on the practice
                                                field. Stretch panels at the
                                                elbows and sides give you a full
                                                range of motion as you work.
                                            </p>
                                            <p>
                                                <br />
                                            </p>
                                            <h3>Specifications</h3>
                                            <ul>
                                                <li>
                                                    Regular fit is wider at the
                                                    body, with a straight
                                                    silhouette
                                                </li>
                                                <li>Ribbed stand-up collar</li>
                                                <li>
                                                    Long sleeves with ribbed
                                                    cuffs
                                                </li>
                                                <li>
                                                    100% polyester doubleknit
                                                </li>
                                                <li>
                                                    Front zip pockets; Full zip;
                                                    Ribbing details; Ribbed hem
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Quill */}
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">Media</h4>
                                    {/* Unfold */}
                                    <div className="hs-unfold">
                                        <a
                                            className="js-hs-unfold-invoker btn btn-sm btn-ghost-secondary"
                                            href="javascript:;"
                                            data-hs-unfold-options='{
                 "target": "#mediaDropdown",
                 "type": "css-animation"
               }'
                                        >
                                            Add media from URL{" "}
                                            <i className="tio-chevron-down" />
                                        </a>
                                        <div
                                            id="mediaDropdown"
                                            className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right mt-1"
                                        >
                                            <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                data-toggle="modal"
                                                data-target="#addImageFromURLModal"
                                            >
                                                <i className="tio-link dropdown-item-icon" />{" "}
                                                Add image from URL
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                data-toggle="modal"
                                                data-target="#embedVideoModal"
                                            >
                                                <i className="tio-youtube-outlined dropdown-item-icon" />{" "}
                                                Embed video
                                            </a>
                                        </div>
                                    </div>
                                    {/* End Unfold */}
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Gallery */}
                                    <div
                                        id="fancyboxGallery"
                                        className="js-fancybox row justify-content-sm-center gx-2"
                                        data-hs-fancybox-options='{
                 "selector": "#fancyboxGallery .js-fancybox-item"
               }'
                                    >
                                        <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card card-sm">
                                                <img
                                                    className="card-img-top"
                                                    src="assets\img\400x400\img7.jpg"
                                                    alt="Image Description"
                                                />
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="col">
                                                            <a
                                                                className="js-fancybox-item text-body"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="View"
                                                                data-src="./assets/img/725x1080/img1.jpg"
                                                                data-caption="Image #01"
                                                            >
                                                                <i className="tio-visible-outlined" />
                                                            </a>
                                                        </div>
                                                        <div className="col column-divider">
                                                            <a
                                                                className="text-danger"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Delete"
                                                            >
                                                                <i className="tio-delete-outlined" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Row */}
                                                </div>
                                            </div>
                                            {/* End Card */}
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card card-sm">
                                                <img
                                                    className="card-img-top"
                                                    src="assets\img\400x400\img8.jpg"
                                                    alt="Image Description"
                                                />
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="col">
                                                            <a
                                                                className="js-fancybox-item text-body"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="View"
                                                                data-src="./assets/img/1920x1080/img1.jpg"
                                                                data-caption="Image #02"
                                                            >
                                                                <i className="tio-visible-outlined" />
                                                            </a>
                                                        </div>
                                                        <div className="col column-divider">
                                                            <a
                                                                className="text-danger"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Delete"
                                                            >
                                                                <i className="tio-delete-outlined" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Row */}
                                                </div>
                                            </div>
                                            {/* End Card */}
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card card-sm">
                                                <img
                                                    className="card-img-top"
                                                    src="assets\img\400x400\img9.jpg"
                                                    alt="Image Description"
                                                />
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="col">
                                                            <a
                                                                className="js-fancybox-item text-body"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="View"
                                                                data-src="./assets/img/1920x1080/img2.jpg"
                                                                data-caption="Image #03"
                                                            >
                                                                <i className="tio-visible-outlined" />
                                                            </a>
                                                        </div>
                                                        <div className="col column-divider">
                                                            <a
                                                                className="text-danger"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Delete"
                                                            >
                                                                <i className="tio-delete-outlined" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Row */}
                                                </div>
                                            </div>
                                            {/* End Card */}
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                                            {/* Card */}
                                            <div className="card card-sm">
                                                <img
                                                    className="card-img-top"
                                                    src="assets\img\400x400\img10.jpg"
                                                    alt="Image Description"
                                                />
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="col">
                                                            <a
                                                                className="js-fancybox-item text-body"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="View"
                                                                data-src="./assets/img/1920x1080/img3.jpg"
                                                                data-caption="Image #04"
                                                            >
                                                                <i className="tio-visible-outlined" />
                                                            </a>
                                                        </div>
                                                        <div className="col column-divider">
                                                            <a
                                                                className="text-danger"
                                                                href="javascript:;"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Delete"
                                                            >
                                                                <i className="tio-delete-outlined" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Row */}
                                                </div>
                                            </div>
                                            {/* End Card */}
                                        </div>
                                    </div>
                                    {/* End Gallery */}
                                    {/* Dropzone */}
                                    <div
                                        id="attachFilesNewProjectLabel"
                                        className="js-dropzone dropzone-custom custom-file-boxed"
                                    >
                                        <div className="dz-message custom-file-boxed-label">
                                            <img
                                                className="avatar avatar-xl avatar-4by3 mb-3"
                                                src="assets\svg\illustrations\browse.svg"
                                                alt="Image Description"
                                            />
                                            <h5 className="mb-1">
                                                Choose files to upload
                                            </h5>
                                            <p className="mb-2">or</p>
                                            <span className="btn btn-sm btn-primary">
                                                Browse files
                                            </span>
                                        </div>
                                    </div>
                                    {/* End Dropzone */}
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div
                                className="js-add-field card mb-3 mb-lg-5"
                                data-hs-add-field-options='{
            "template": "#addVariantsTemplate",
            "container": "#addVariantsContainer",
            "defaultCreated": 0,
            "limit": 100
          }'
                            >
                                {/* Header */}
                                <div className="card-header">
                                    <div className="row justify-content-between align-items-center flex-grow-1">
                                        <div className="col-12 col-sm mb-3 mb-sm-0">
                                            <h4 className="card-header-title">
                                                Variants
                                            </h4>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                {/* Datatable Info */}
                                                <div
                                                    id="datatableCounterInfo"
                                                    style={{ display: "none" }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <span className="font-size-sm mr-3">
                                                            <span id="datatableCounter">
                                                                0
                                                            </span>
                                                            Selected
                                                        </span>
                                                        <a
                                                            className="btn btn-sm btn-outline-danger"
                                                            href="javascript:;"
                                                        >
                                                            <i className="tio-delete-outlined" />{" "}
                                                            Delete
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Datatable Info */}
                                                <a
                                                    className="js-create-field btn btn-sm btn-ghost-secondary"
                                                    href="javascript:;"
                                                >
                                                    <i className="tio-add" />{" "}
                                                    Add variant
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Row */}
                                </div>
                                {/* End Header */}
                                {/* Table */}
                                <div className="table-responsive datatable-custom">
                                    <table
                                        id="datatable"
                                        className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                        data-hs-datatables-options='{
                   "columnDefs": [{
                      "targets": [0, 1, 6],
                      "orderable": false
                    }],
                   "order": [],
                   "pageLength": 15,
                   "isResponsive": false,
                   "isShowPaging": false
                 }'
                                    >
                                        <thead className="thead-light">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="table-column-pr-0"
                                                >
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="datatableCheckAll"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="datatableCheckAll"
                                                        />
                                                    </div>
                                                </th>
                                                <th />
                                                <th className="table-column-pl-0">
                                                    Size
                                                </th>
                                                <th className="table-column-pl-0">
                                                    Color
                                                </th>
                                                <th className="table-column-pl-0">
                                                    Price
                                                </th>
                                                <th className="table-column-pl-0">
                                                    Quantity
                                                </th>
                                                <th className="table-column-pl-0" />
                                            </tr>
                                        </thead>
                                        <tbody id="addVariantsContainer">
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck1"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck1"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="S"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="White"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck2"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck2"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="M"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="White"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck3"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck3"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="L"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="White"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck4"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck4"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="XL"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="White"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck5"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck5"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="S"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Black"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck6"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck6"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="M"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Black"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck7"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck7"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="L"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Black"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck8"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck8"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="XL"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Black"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={45.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={10}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck9"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck9"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="S"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Orange"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={50.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={5}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck10"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck10"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="M"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Orange"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={50.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={5}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck11"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck11"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="L"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Orange"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={50.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={5}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="table-column-pr-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="productVariationsCheck12"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="productVariationsCheck12"
                                                        />
                                                    </div>
                                                </td>
                                                <th>
                                                    <img
                                                        className="avatar"
                                                        src="assets\img\400x400\img7.jpg"
                                                        alt="Image Description"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="XL"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="Orange"
                                                    />
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                USD
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-8"
                                                            defaultValue={50.0}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="table-column-pl-0">
                                                    {/* Quantity Counter */}
                                                    <div className="js-quantity-counter input-group-quantity-counter">
                                                        <input
                                                            type="number"
                                                            className="js-result form-control input-group-quantity-counter-control"
                                                            defaultValue={5}
                                                        />
                                                        <div className="input-group-quantity-counter-toggle">
                                                            <a
                                                                className="js-minus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-remove" />
                                                            </a>
                                                            <a
                                                                className="js-plus input-group-quantity-counter-btn"
                                                                href="javascript:;"
                                                            >
                                                                <i className="tio-add" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* End Quantity Counter */}
                                                </th>
                                                <th className="table-column-pl-0">
                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Edit group"
                                                    >
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-edit" />{" "}
                                                            Edit
                                                        </a>
                                                        <a
                                                            className="btn btn-white"
                                                            href="#"
                                                        >
                                                            <i className="tio-delete-outlined" />
                                                        </a>
                                                    </div>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* End Table */}
                                {/* Footer */}
                                <div className="card-footer">
                                    <a
                                        className="js-create-field btn btn-sm btn-ghost-secondary"
                                        href="javascript:;"
                                    >
                                        <i className="tio-add" /> Add variant
                                    </a>
                                </div>
                                {/* End Footer */}
                                {/* Add Variants Field */}
                                <table style={{ display: "none" }}>
                                    <tbody>
                                        <tr id="addVariantsTemplate">
                                            <td className="table-column-pr-0">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="productVariationsCheck13"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="productVariationsCheck13"
                                                    />
                                                </div>
                                            </td>
                                            <th>
                                                <img
                                                    className="avatar"
                                                    src="assets\img\400x400\img2.jpg"
                                                    alt="Image Description"
                                                />
                                            </th>
                                            <th className="table-column-pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </th>
                                            <th className="table-column-pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </th>
                                            <th className="table-column-pl-0">
                                                <div className="input-group input-group-merge">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            USD
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control pl-8"
                                                    />
                                                </div>
                                            </th>
                                            <th className="table-column-pl-0">
                                                {/* Quantity Counter */}
                                                <div className="js-quantity-counter-dynamic input-group-quantity-counter">
                                                    <input
                                                        type="number"
                                                        className="js-result form-control input-group-quantity-counter-control"
                                                        defaultValue={1}
                                                    />
                                                    <div className="input-group-quantity-counter-toggle">
                                                        <a
                                                            className="js-minus input-group-quantity-counter-btn"
                                                            href="javascript:;"
                                                        >
                                                            <i className="tio-remove" />
                                                        </a>
                                                        <a
                                                            className="js-plus input-group-quantity-counter-btn"
                                                            href="javascript:;"
                                                        >
                                                            <i className="tio-add" />
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Quantity Counter */}
                                            </th>
                                            <th className="table-column-pl-0">
                                                <div
                                                    className="btn-group"
                                                    role="group"
                                                    aria-label="Edit group"
                                                >
                                                    <a
                                                        className="btn btn-white"
                                                        href="#"
                                                    >
                                                        <i className="tio-edit" />{" "}
                                                        Edit
                                                    </a>
                                                    <a
                                                        className="btn btn-white"
                                                        href="#"
                                                    >
                                                        <i className="tio-delete-outlined" />
                                                    </a>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* End Add Variants Field */}
                            </div>
                            {/* End Card */}
                        </div>
                        <div className="col-lg-4">
                            {/* Card */}
                            <div className="card mb-3 mb-lg-5">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Pricing
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Form Group */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="priceNameLabel"
                                            className="input-label"
                                        >
                                            Price
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="priceName"
                                                id="priceNameLabel"
                                                placeholder={0.0}
                                                aria-label={0.0}
                                                defaultValue={45.0}
                                            />
                                            <div className="input-group-append">
                                                {/* Select */}
                                                <div
                                                    id="priceCurrencySelect"
                                                    className="select2-custom select2-custom-right"
                                                >
                                                    <select
                                                        className="js-select2-custom custom-select"
                                                        size={1}
                                                        style={{ opacity: 0 }}
                                                        data-hs-select2-options='{
                            "dropdownParent": "#priceCurrencySelect",
                            "dropdownAutoWidth": true,
                            "width": true
                          }'
                                                    >
                                                        <option
                                                            value="USD"
                                                            selected
                                                        >
                                                            USD
                                                        </option>
                                                        <option value="AED">
                                                            AED
                                                        </option>
                                                        <option value="AFN">
                                                            AFN
                                                        </option>
                                                        <option value="ALL">
                                                            ALL
                                                        </option>
                                                        <option value="AMD">
                                                            AMD
                                                        </option>
                                                        <option value="ANG">
                                                            ANG
                                                        </option>
                                                        <option value="AOA">
                                                            AOA
                                                        </option>
                                                        <option value="ARS">
                                                            ARS
                                                        </option>
                                                        <option value="AUD">
                                                            AUD
                                                        </option>
                                                        <option value="AWG">
                                                            AWG
                                                        </option>
                                                        <option value="AZN">
                                                            AZN
                                                        </option>
                                                        <option value="BAM">
                                                            BAM
                                                        </option>
                                                        <option value="BBD">
                                                            BBD
                                                        </option>
                                                        <option value="BDT">
                                                            BDT
                                                        </option>
                                                        <option value="BGN">
                                                            BGN
                                                        </option>
                                                        <option value="BIF">
                                                            BIF
                                                        </option>
                                                        <option value="BMD">
                                                            BMD
                                                        </option>
                                                        <option value="BND">
                                                            BND
                                                        </option>
                                                        <option value="BOB">
                                                            BOB
                                                        </option>
                                                        <option value="BRL">
                                                            BRL
                                                        </option>
                                                        <option value="BSD">
                                                            BSD
                                                        </option>
                                                        <option value="BWP">
                                                            BWP
                                                        </option>
                                                        <option value="BZD">
                                                            BZD
                                                        </option>
                                                        <option value="CAD">
                                                            CAD
                                                        </option>
                                                        <option value="CDF">
                                                            CDF
                                                        </option>
                                                        <option value="CHF">
                                                            CHF
                                                        </option>
                                                        <option value="CLP">
                                                            CLP
                                                        </option>
                                                        <option value="CNY">
                                                            CNY
                                                        </option>
                                                        <option value="COP">
                                                            COP
                                                        </option>
                                                        <option value="CRC">
                                                            CRC
                                                        </option>
                                                        <option value="CVE">
                                                            CVE
                                                        </option>
                                                        <option value="CZK">
                                                            CZK
                                                        </option>
                                                        <option value="DJF">
                                                            DJF
                                                        </option>
                                                        <option value="DKK">
                                                            DKK
                                                        </option>
                                                        <option value="DOP">
                                                            DOP
                                                        </option>
                                                        <option value="DZD">
                                                            DZD
                                                        </option>
                                                        <option value="EGP">
                                                            EGP
                                                        </option>
                                                        <option value="ETB">
                                                            ETB
                                                        </option>
                                                        <option value="EUR">
                                                            EUR
                                                        </option>
                                                        <option value="FJD">
                                                            FJD
                                                        </option>
                                                        <option value="FKP">
                                                            FKP
                                                        </option>
                                                        <option value="GBP">
                                                            GBP
                                                        </option>
                                                        <option value="GEL">
                                                            GEL
                                                        </option>
                                                        <option value="GIP">
                                                            GIP
                                                        </option>
                                                        <option value="GMD">
                                                            GMD
                                                        </option>
                                                        <option value="GNF">
                                                            GNF
                                                        </option>
                                                        <option value="GTQ">
                                                            GTQ
                                                        </option>
                                                        <option value="GYD">
                                                            GYD
                                                        </option>
                                                        <option value="HKD">
                                                            HKD
                                                        </option>
                                                        <option value="HNL">
                                                            HNL
                                                        </option>
                                                        <option value="HRK">
                                                            HRK
                                                        </option>
                                                        <option value="HTG">
                                                            HTG
                                                        </option>
                                                        <option value="HUF">
                                                            HUF
                                                        </option>
                                                        <option value="IDR">
                                                            IDR
                                                        </option>
                                                        <option value="ILS">
                                                            ILS
                                                        </option>
                                                        <option value="INR">
                                                            INR
                                                        </option>
                                                        <option value="ISK">
                                                            ISK
                                                        </option>
                                                        <option value="JMD">
                                                            JMD
                                                        </option>
                                                        <option value="JPY">
                                                            JPY
                                                        </option>
                                                        <option value="KES">
                                                            KES
                                                        </option>
                                                        <option value="KGS">
                                                            KGS
                                                        </option>
                                                        <option value="KHR">
                                                            KHR
                                                        </option>
                                                        <option value="KMF">
                                                            KMF
                                                        </option>
                                                        <option value="KRW">
                                                            KRW
                                                        </option>
                                                        <option value="KYD">
                                                            KYD
                                                        </option>
                                                        <option value="KZT">
                                                            KZT
                                                        </option>
                                                        <option value="LAK">
                                                            LAK
                                                        </option>
                                                        <option value="LBP">
                                                            LBP
                                                        </option>
                                                        <option value="LKR">
                                                            LKR
                                                        </option>
                                                        <option value="LRD">
                                                            LRD
                                                        </option>
                                                        <option value="LSL">
                                                            LSL
                                                        </option>
                                                        <option value="MAD">
                                                            MAD
                                                        </option>
                                                        <option value="MDL">
                                                            MDL
                                                        </option>
                                                        <option value="MGA">
                                                            MGA
                                                        </option>
                                                        <option value="MKD">
                                                            MKD
                                                        </option>
                                                        <option value="MMK">
                                                            MMK
                                                        </option>
                                                        <option value="MNT">
                                                            MNT
                                                        </option>
                                                        <option value="MOP">
                                                            MOP
                                                        </option>
                                                        <option value="MRO">
                                                            MRO
                                                        </option>
                                                        <option value="MUR">
                                                            MUR
                                                        </option>
                                                        <option value="MVR">
                                                            MVR
                                                        </option>
                                                        <option value="MWK">
                                                            MWK
                                                        </option>
                                                        <option value="MXN">
                                                            MXN
                                                        </option>
                                                        <option value="MYR">
                                                            MYR
                                                        </option>
                                                        <option value="MZN">
                                                            MZN
                                                        </option>
                                                        <option value="NAD">
                                                            NAD
                                                        </option>
                                                        <option value="NGN">
                                                            NGN
                                                        </option>
                                                        <option value="NIO">
                                                            NIO
                                                        </option>
                                                        <option value="NOK">
                                                            NOK
                                                        </option>
                                                        <option value="NPR">
                                                            NPR
                                                        </option>
                                                        <option value="NZD">
                                                            NZD
                                                        </option>
                                                        <option value="PAB">
                                                            PAB
                                                        </option>
                                                        <option value="PEN">
                                                            PEN
                                                        </option>
                                                        <option value="PGK">
                                                            PGK
                                                        </option>
                                                        <option value="PHP">
                                                            PHP
                                                        </option>
                                                        <option value="PKR">
                                                            PKR
                                                        </option>
                                                        <option value="PLN">
                                                            PLN
                                                        </option>
                                                        <option value="PYG">
                                                            PYG
                                                        </option>
                                                        <option value="QAR">
                                                            QAR
                                                        </option>
                                                        <option value="RON">
                                                            RON
                                                        </option>
                                                        <option value="RSD">
                                                            RSD
                                                        </option>
                                                        <option value="RUB">
                                                            RUB
                                                        </option>
                                                        <option value="RWF">
                                                            RWF
                                                        </option>
                                                        <option value="SAR">
                                                            SAR
                                                        </option>
                                                        <option value="SBD">
                                                            SBD
                                                        </option>
                                                        <option value="SCR">
                                                            SCR
                                                        </option>
                                                        <option value="SEK">
                                                            SEK
                                                        </option>
                                                        <option value="SGD">
                                                            SGD
                                                        </option>
                                                        <option value="SHP">
                                                            SHP
                                                        </option>
                                                        <option value="SLL">
                                                            SLL
                                                        </option>
                                                        <option value="SOS">
                                                            SOS
                                                        </option>
                                                        <option value="SRD">
                                                            SRD
                                                        </option>
                                                        <option value="STD">
                                                            STD
                                                        </option>
                                                        <option value="SZL">
                                                            SZL
                                                        </option>
                                                        <option value="THB">
                                                            THB
                                                        </option>
                                                        <option value="TJS">
                                                            TJS
                                                        </option>
                                                        <option value="TOP">
                                                            TOP
                                                        </option>
                                                        <option value="TRY">
                                                            TRY
                                                        </option>
                                                        <option value="TTD">
                                                            TTD
                                                        </option>
                                                        <option value="TWD">
                                                            TWD
                                                        </option>
                                                        <option value="TZS">
                                                            TZS
                                                        </option>
                                                        <option value="UAH">
                                                            UAH
                                                        </option>
                                                        <option value="UGX">
                                                            UGX
                                                        </option>
                                                        <option value="UYU">
                                                            UYU
                                                        </option>
                                                        <option value="UZS">
                                                            UZS
                                                        </option>
                                                        <option value="VND">
                                                            VND
                                                        </option>
                                                        <option value="VUV">
                                                            VUV
                                                        </option>
                                                        <option value="WST">
                                                            WST
                                                        </option>
                                                        <option value="XAF">
                                                            XAF
                                                        </option>
                                                        <option value="XCD">
                                                            XCD
                                                        </option>
                                                        <option value="XOF">
                                                            XOF
                                                        </option>
                                                        <option value="XPF">
                                                            XPF
                                                        </option>
                                                        <option value="YER">
                                                            YER
                                                        </option>
                                                        <option value="ZAR">
                                                            ZAR
                                                        </option>
                                                        <option value="ZMW">
                                                            ZMW
                                                        </option>
                                                    </select>
                                                </div>
                                                {/* End Select */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Form Group */}
                                    <div className="mb-2">
                                        <a
                                            className="d-inline-block"
                                            href="javascript:;"
                                            data-toggle="modal"
                                            data-target="#productsAdvancedFeaturesModal"
                                        >
                                            <i className="tio-star tio-lg text-warning mr-1" />{" "}
                                            Set "Compare to" price
                                        </a>
                                    </div>
                                    <a
                                        className="d-inline-block"
                                        href="javascript:;"
                                        data-toggle="modal"
                                        data-target="#productsAdvancedFeaturesModal"
                                    >
                                        <i className="tio-star tio-lg text-warning mr-1" />{" "}
                                        Bulk discount pricing
                                    </a>
                                    <hr className="my-4" />
                                    {/* Toggle Switch */}
                                    <label
                                        className="row toggle-switch"
                                        htmlFor="availabilitySwitch1"
                                    >
                                        <span className="col-8 col-sm-9 toggle-switch-content">
                                            <span className="text-dark">
                                                Availability{" "}
                                                <i
                                                    className="tio-help-outlined text-body ml-1"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Product availability switch toggler."
                                                />
                                            </span>
                                        </span>
                                        <span className="col-4 col-sm-3">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-input"
                                                id="availabilitySwitch1"
                                                defaultChecked
                                            />
                                            <span className="toggle-switch-label ml-auto">
                                                <span className="toggle-switch-indicator" />
                                            </span>
                                        </span>
                                    </label>
                                    {/* End Toggle Switch */}
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                            {/* Card */}
                            <div className="card">
                                {/* Header */}
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Organization
                                    </h4>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="card-body">
                                    {/* Form Group */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="vendorLabel"
                                            className="input-label"
                                        >
                                            Vendor
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="vendor"
                                            id="vendorLabel"
                                            placeholder="eg. Nike"
                                            aria-label="eg. Nike"
                                            defaultValue="Adidas"
                                        />
                                    </div>
                                    {/* End Form Group */}
                                    {/* Form Group */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="categoryLabel"
                                            className="input-label"
                                        >
                                            Category
                                        </label>
                                        {/* Select */}
                                        <select
                                            className="js-select2-custom custom-select"
                                            size={1}
                                            style={{ opacity: 0 }}
                                            id="categoryLabel"
                                            data-hs-select2-options='{
                      "minimumResultsForSearch": "Infinity",
                      "placeholder": "Select category"
                    }'
                                        >
                                            <option label="empty" />
                                            <option value="Clothing" selected>
                                                Clothing
                                            </option>
                                            <option value="Shoes">Shoes</option>
                                            <option value="Electronics">
                                                Electronics
                                            </option>
                                            <option value="Others">
                                                Others
                                            </option>
                                        </select>
                                        {/* End Select */}
                                    </div>
                                    {/* Form Group */}
                                    {/* Form Group */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="collectionsLabel"
                                            className="input-label"
                                        >
                                            Collections
                                        </label>
                                        {/* Select */}
                                        <select
                                            className="js-select2-custom custom-select"
                                            size={1}
                                            style={{ opacity: 0 }}
                                            id="collectionsLabel"
                                            data-hs-select2-options='{
                      "minimumResultsForSearch": "Infinity",
                      "placeholder": "Select collections"
                    }'
                                        >
                                            <option label="empty" />
                                            <option value="Winter">
                                                Winter
                                            </option>
                                            <option value="Spring">
                                                Spring
                                            </option>
                                            <option value="Summer" selected>
                                                Summer
                                            </option>
                                            <option value="Autumn">
                                                Autumn
                                            </option>
                                        </select>
                                        {/* End Select */}
                                        <span className="form-text">
                                            Add this product to a collection so
                                            it’s easy to find in your store.
                                        </span>
                                    </div>
                                    {/* Form Group */}
                                    <label
                                        htmlFor="tagsLabel"
                                        className="input-label"
                                    >
                                        Tags
                                    </label>
                                    <input
                                        type="text"
                                        className="js-tagify tagify-form-control form-control"
                                        name="tagsName"
                                        id="tagsLabel"
                                        placeholder="Enter tags here"
                                        aria-label="Enter tags here"
                                        defaultValue="white, black"
                                    />
                                </div>
                                {/* Body */}
                            </div>
                            {/* End Card */}
                        </div>
                    </div>
                    {/* End Row */}
                    <div
                        className="position-fixed bottom-0 content-centered-x w-100 z-index-99 mb-3"
                        style={{ maxWidth: "40rem" }}
                    >
                        {/* Card */}

                        {/* End Card */}
                    </div>
                </div>
                {/* End Content */}
                {/* Footer */}
                <div className="footer">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <p className="font-size-sm mb-0">
                                © Front.{" "}
                                <span className="d-none d-sm-inline-block">
                                    2020 Htmlstream.
                                </span>
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-end">
                                {/* List Dot */}
                                <ul className="list-inline list-separator">
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="list-separator-link"
                                            href="#"
                                        >
                                            License
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* Keyboard Shortcuts Toggle */}
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
                        "target": "#keyboardShortcutsSidebar",
                        "type": "css-animation",
                        "animationIn": "fadeInRight",
                        "animationOut": "fadeOutRight",
                        "hasOverlay": true,
                        "smartPositionOff": true
                       }'
                                            >
                                                <i className="tio-command-key" />
                                            </a>
                                        </div>
                                        {/* End Keyboard Shortcuts Toggle */}
                                    </li>
                                </ul>
                                {/* End List Dot */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Footer */}
            </main>
        </>
    );
};

export default ProductDetails;
