import Http from "./Http";
import { getToken } from "../shared/ultils/token";

export const getAllRobot = (page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(`${process.env.REACT_APP_API_GET_ALL_ROBOT}?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};

export const getRobotDetail = (robotId) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(`${process.env.REACT_APP_API_GET_ROBOT_DETAIL}/${robotId}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });
};

export const getAllRobotTapping = (page = 0) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_ALL_ROBOT_TAPPING}?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};

export const getRobotTappingDetail = (robotTappingId) => {
  const token = getToken(); // Retrieve the JWT token from storage
  return Http.get(
    `${process.env.REACT_APP_API_GET_ROBOT_TAPPING_DETAIL}/${robotTappingId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
      },
    }
  );
};
