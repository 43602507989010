import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFieldsByRegionId } from '../services/areas';

const FieldList = () => {
  const { regionId } = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (regionId) {
      const fetchFields = async () => {
        try {
          const response = await getFieldsByRegionId(regionId, page);
          setFields(response.data.content);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error('Error fetching fields:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchFields();
    }
  }, [regionId, page]);

  const handleFieldClick = (fieldId) => {
    navigate(`/rubber-tree/${fieldId}`);
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Fields</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="row">
            {fields.map(field => (
              <div
                key={field.fieldId}
                className="col-lg-4 col-md-6 mb-4"
                onClick={() => handleFieldClick(field.fieldId)} // Navigate to the list of rubber trees
                style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
              >
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title">{field.fieldName}</h5>
                    <p className="card-text">Area: {field.area}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-outline-primary me-2"
              disabled={page === 0}
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <div>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-outline-primary mx-1 ${
                    index === page ? 'active' : ''
                  }`}
                  onClick={() => handleSelectPage(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={page === totalPages - 1}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FieldList;
