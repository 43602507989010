import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDroneDetail } from "../services/drone";

const DroneDetail = () => {
  const { droneId } = useParams();
  const navigate = useNavigate();
  const [droneDetail, setDroneDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDroneDetail = async () => {
      try {
        const response = await getDroneDetail(droneId);
        if (response.data) {
          setDroneDetail(response.data);
        } else {
          setDroneDetail(null);
        }
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching drone detail:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDroneDetail();
  }, [droneId]);

  const handleSCSClick = (droneId) => {
    navigate(`/scs/${droneId}`);
  };

  return (
    <div
      className="container mt-5 d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div className="col-md-10 col-lg-8">
        <h2 className="text-center mb-4" style={{ fontSize: "2rem" }}>
          Drone Detail
        </h2>
        {loading ? (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Loading...
          </p>
        ) : droneDetail ? (
          <div
            className="card shadow-lg hover-card"
            style={{
              padding: "2rem",
              fontSize: "1.5rem",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <div className="card-body">
              <h5
                className="card-title mb-4 text-center"
                style={{ fontSize: "2rem" }}
              >
                Drone ID: {droneDetail.droneId}
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Drone Direction:</strong> {droneDetail.direction}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Current Location:</strong>{" "}
                    {droneDetail.currentLocation}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Speed: </strong> {droneDetail.speed}
                  </div>
                  <div className="card-text mb-3">
                    <strong>Height: </strong> {droneDetail.height} meters
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-img mb-3">
                    <img src={droneDetail.image} alt="DroneImage" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-btn col-md">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleSCSClick(droneId)}
                    >
                      <strong>Sensor Control System</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Drone detail not found.
          </p>
        )}
      </div>
    </div>
  );
};

export default DroneDetail;
