import React from "react";

const Header = ({ onToggle, isNavbarOpen }) => {
  // const { isHeaderVisible, hideHeader } = useNavbar();

  return (
    <>
      {/* Navbar sẽ hiển thị khi isHeaderVisible là true */}
      {isNavbarOpen && (
        // navbar-vertical-aside-initialized
        <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-light navbar-vertical-aside-initialized ">
          <div className="navbar-vertical-container">
            {/* <div className="navbar-vertical-footer-offset"> */}
            <div className="navbar-brand-wrapper justify-content-between">
              {/* Logo */}
              <a
                className="navbar-brand"
                href="..\index.html"
                aria-label="Front"
              >
                <img
                  className="navbar-brand-logo"
                  src="..\assets\svg\logos\logo.svg"
                  alt="Logo"
                />
                <img
                  className="navbar-brand-logo-mini"
                  src="..\assets\svg\logos\logo-short.svg"
                  alt="Logo"
                />
              </a>
              {/* End Logo */}
              {/* Navbar Vertical Toggle */}
              <button
                type="button"
                className="btn btn-icon btn-xs btn-ghost-dark"
                onClick={onToggle}
              >
                <i className="tio-clear tio-lg" />
              </button>

              {/* End Navbar Vertical Toggle */}
            </div>
            {/* Content */}
            <div className="navbar-vertical-content">
              <ul className="navbar-nav navbar-nav-lg nav-tabs">
                <li className="navbar-vertical-aside-has-menu">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link"
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/admin/dashboard`}
                    title="Dashboard"
                  >
                    <i className="tio-home-vs-1-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Dashboard
                    </span>
                  </a>
                </li>
                {/* End Dashboards */}
                <li className="nav-item">
                  <small className="nav-subtitle" title="Forestry Vam">
                    VAM Forestry System
                  </small>
                  <small className="tio-more-horizontal nav-subtitle-replacer" />
                </li>
                {/* Pages */}

                <li className="navbar-vertical-aside-has-menu ">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle "
                    // href="/country"
                    title="Users Management"
                  >
                    <i className="tio-pages-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Users Management
                    </span>
                  </a>
                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}`}
                        title="User"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">User</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/customers`}
                        title="Customer"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Customer</span>
                      </a>
                    </li>
                  </ul>
                </li>
                {/* End Pages */}
                {/* customers management */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle "
                    title="Intelligent Fleet"
                  >
                    <i className="tio-pages-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Intelligent Fleet
                    </span>
                  </a>
                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/drone`}
                        title="Drone"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Drone</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/robot`}
                        title="Robot"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Robot</span>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item ">
                  <a
                    className="js-nav-tooltip-link nav-link "
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/country`}
                    title="Country"
                    data-placement="left"
                  >
                    <i className="tio-visible-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Country
                    </span>
                  </a>
                </li>

                <li className="nav-item ">
                  <a
                    className="js-nav-tooltip-link nav-link "
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/products`}
                    title="Product"
                    data-placement="left"
                  >
                    <i className="tio-visible-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Product
                    </span>
                  </a>
                </li>

                <li className="nav-item ">
                  <a
                    className="js-nav-tooltip-link nav-link "
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/task`}
                    title="Task"
                    data-placement="left"
                  >
                    <i className="tio-visible-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Task
                    </span>
                  </a>
                </li>

                {/* Authentication
                <li className="navbar-vertical-aside-has-menu ">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle "
                    href="javascript:;"
                    title="Authentication"
                  >
                    <i className="tio-lock-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Authentication
                    </span>
                  </a>
                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="navbar-vertical-aside-has-menu ">
                      <a
                        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                        href="javascript:;"
                        title="Sign In"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Sign In</span>
                      </a>
                      <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="http://localhost:3000/signin"
                            title="Basic"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Basic</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="..\authentication-signin-cover.html"
                            title="Cover"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Cover</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="navbar-vertical-aside-has-menu ">
                      <a
                        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                        href="javascript:;"
                        title="Sign Up"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Sign Up</span>
                      </a>
                      <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="http://localhost:3000/signup"
                            title="Basic"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Basic</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="..\authentication-signup-cover.html"
                            title="Cover"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Cover</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="navbar-vertical-aside-has-menu ">
                      <a
                        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                        href="javascript:;"
                        title="Reset Password"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Reset Password</span>
                      </a>
                      <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="http://localhost:3000/forgetpassword"
                            title="Basic"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Basic</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="..\authentication-reset-password-cover.html"
                            title="Cover"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Cover</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="navbar-vertical-aside-has-menu ">
                      <a
                        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                        href="javascript:;"
                        title="Email Verification"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">
                          Email Verification
                        </span>
                      </a>
                      <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="http://localhost:3000/emailverification"
                            title="Basic"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Basic</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="..\authentication-email-verification-cover.html"
                            title="Cover"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Cover</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="navbar-vertical-aside-has-menu ">
                      <a
                        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                        href="javascript:;"
                        title="2-step Verification"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">
                          2-step Verification
                        </span>
                      </a>
                      <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="http://localhost:3000/verificationstep2"
                            title="Basic"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Basic</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            href="..\authentication-two-step-verification-cover.html"
                            title="Cover"
                          >
                            <span className="tio-circle-outlined nav-indicator-icon" />
                            <span className="text-truncate">Cover</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#welcomeMessageModal"
                        title="Welcome Message"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Review By Manager</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        href="..\error-404.html"
                        title="Error 404"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Error 404</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        href="..\error-500.html"
                        title="Error 500"
                      >
                        <span className="tio-circle nav-indicator-icon" />
                        <span className="text-truncate">Error 500</span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* End Authentication */}
                {/* <li className="nav-item ">
                  <a
                    className="js-nav-tooltip-link nav-link "
                    href="..\welcome-page.html"
                    title="Welcome page"
                    data-placement="left"
                  >
                    <i className="tio-visible-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Review By Manager
                    </span>
                  </a>
                </li> */}
                <li className="nav-item">
                  <small className="nav-subtitle" title="Layouts">
                    Review
                  </small>
                  <small className="tio-more-horizontal nav-subtitle-replacer" />
                </li>
                <li className="nav-item active">
                  <a
                    className="js-nav-tooltip-link nav-link active"
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/managereviews`}
                    title="Review By Manager"
                    data-placement="left"
                  >
                    <i className="tio-dashboard-vs-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Review By Manager
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <div className="nav-divider" />
                </li>
                <li className="nav-item">
                  <small className="nav-subtitle" title="Documentation">
                    Order
                  </small>
                  <small className="tio-more-horizontal nav-subtitle-replacer" />
                </li>

                <li className="nav-item ">
                  <a
                    className="js-nav-tooltip-link nav-link"
                    href={`${process.env.REACT_APP_FRONTEND_API_URL_DEV}/orders`}
                    title="Components"
                    data-placement="left"
                  >
                    <i className="tio-layers-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Order
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <small className="tio-more-horizontal nav-subtitle-replacer" />
                </li>
                {/* Front Builder */}
                <li className="nav-item nav-footer-item ">
                  <a
                    className="d-none d-md-flex js-hs-unfold-invoker nav-link nav-link-toggle"
                    href="javascript:;"
                    data-hs-unfold-options='{
             "target": "#styleSwitcherDropdown",
             "type": "css-animation",
             "animationIn": "fadeInRight",
             "animationOut": "fadeOutRight",
             "hasOverlay": true,
             "smartPositionOff": true
           }'
                  >
                    <i className="tio-tune nav-icon" />
                  </a>
                  <a
                    className="d-flex d-md-none nav-link nav-link-toggle"
                    href="javascript:;"
                  >
                    <i className="tio-tune nav-icon" />
                  </a>
                </li>
                {/* End Front Builder */}
                {/* Help */}
                <li className="navbar-vertical-aside-has-menu nav-footer-item ">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle "
                    href="javascript:;"
                    title="Help"
                  >
                    <i className="tio-home-vs-1-outlined nav-icon" />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Help
                    </span>
                  </a>
                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        title="Resources & tutorials"
                      >
                        <i className="tio-book-outlined dropdown-item-icon" />{" "}
                        Resources &amp; tutorials
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        title="Keyboard shortcuts"
                      >
                        <i className="tio-command-key dropdown-item-icon" />{" "}
                        Keyboard shortcuts
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        title="Connect other apps"
                      >
                        <i className="tio-alt dropdown-item-icon" /> Connect
                        other apps
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="What's new?">
                        <i className="tio-gift dropdown-item-icon" /> What's
                        new?
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="Contact support">
                        <i className="tio-chat-outlined dropdown-item-icon" />{" "}
                        Contact support
                      </a>
                    </li>
                  </ul>
                </li>
                {/* End Help */}
                {/* Language */}
                <li className="navbar-vertical-aside-has-menu nav-footer-item ">
                  <a
                    className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle "
                    href="javascript:;"
                    title="Language"
                  >
                    <img
                      className="avatar avatar-xss avatar-circle"
                      src="..\assets\vendor\flag-icon-css\flags\1x1\us.svg"
                      alt="United States Flag"
                    />
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Language
                    </span>
                  </a>
                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="English (US)">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\us.svg"
                          alt="Flag"
                        />
                        English (US)
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="English (UK)">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                          alt="Flag"
                        />
                        English (UK)
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="Deutsch">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\de.svg"
                          alt="Flag"
                        />
                        Deutsch
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="Dansk">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\dk.svg"
                          alt="Flag"
                        />
                        Dansk
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="Italiano">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\it.svg"
                          alt="Flag"
                        />
                        Italiano
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" title="中文 (繁體)">
                        <img
                          className="avatar avatar-xss avatar-circle mr-2"
                          src="..\assets\vendor\flag-icon-css\flags\1x1\cn.svg"
                          alt="Flag"
                        />
                        中文 (繁體)
                      </a>
                    </li>
                  </ul>
                </li>
                {/* End Language */}
              </ul>
            </div>
            {/* End Content */}
            {/* Footer */}
            {/* <div className="navbar-vertical-footer">
                                <ul className="navbar-vertical-footer-list">
                                    <li className="navbar-vertical-footer-list-item">
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
              "target": "#styleSwitcherDropdown",
              "type": "css-animation",
              "animationIn": "fadeInRight",
              "animationOut": "fadeOutRight",
              "hasOverlay": true,
              "smartPositionOff": true
             }'
                                            >
                                                <i className="tio-tune" />
                                            </a>
                                        </div>
                                    </li>
                                    <li className="navbar-vertical-footer-list-item">
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
              "target": "#otherLinksDropdown",
              "type": "css-animation",
              "animationIn": "slideInDown",
              "hideOnScroll": true
             }'
                                            >
                                                <i className="tio-help-outlined" />
                                            </a>
                                            <div
                                                id="otherLinksDropdown"
                                                className="hs-unfold-content dropdown-unfold dropdown-menu navbar-vertical-footer-dropdown"
                                            >
                                                <span className="dropdown-header">
                                                    Help
                                                </span>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-book-outlined dropdown-item-icon" />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Resources & tutorials"
                                                    >
                                                        Resources &amp;
                                                        tutorials
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-command-key dropdown-item-icon" />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Keyboard shortcuts"
                                                    >
                                                        Keyboard shortcuts
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-alt dropdown-item-icon" />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Connect other apps"
                                                    >
                                                        Connect other apps
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-gift dropdown-item-icon" />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="What's new?"
                                                    >
                                                        What's new?
                                                    </span>
                                                </a>
                                                <div className="dropdown-divider" />
                                                <span className="dropdown-header">
                                                    Contacts
                                                </span>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="tio-chat-outlined dropdown-item-icon" />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Contact support"
                                                    >
                                                        Contact support
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="navbar-vertical-footer-list-item">
                                        <div className="hs-unfold">
                                            <a
                                                className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
                                                href="javascript:;"
                                                data-hs-unfold-options='{
              "target": "#languageDropdown",
              "type": "css-animation",
              "animationIn": "slideInDown",
              "hideOnScroll": true
             }'
                                            >
                                                <img
                                                    className="avatar avatar-xss avatar-circle"
                                                    src="..\assets\vendor\flag-icon-css\flags\1x1\us.svg"
                                                    alt="United States Flag"
                                                />
                                            </a>
                                            <div
                                                id="languageDropdown"
                                                className="hs-unfold-content dropdown-unfold dropdown-menu navbar-vertical-footer-dropdown"
                                            >
                                                <span className="dropdown-header">
                                                    Select language
                                                </span>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\us.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="English"
                                                    >
                                                        English (US)
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\gb.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="English"
                                                    >
                                                        English (UK)
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\de.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Deutsch"
                                                    >
                                                        Deutsch
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\dk.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Dansk"
                                                    >
                                                        Dansk
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\it.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="Italiano"
                                                    >
                                                        Italiano
                                                    </span>
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <img
                                                        className="avatar avatar-xss avatar-circle mr-2"
                                                        src="..\assets\vendor\flag-icon-css\flags\1x1\cn.svg"
                                                        alt="Flag"
                                                    />
                                                    <span
                                                        className="text-truncate pr-2"
                                                        title="中文 (繁體)"
                                                    >
                                                        中文 (繁體)
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
            {/* End Footer */}
            {/* </div> */}
          </div>
        </aside>
      )}
      {/* End Navbar Vertical */}
    </>
  );
};

export default Header;
