import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRobotDetail } from "../services/robot";

const RobotDetails = () => {
  const { robotId } = useParams();
  const [robotDetail, setRobotDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRobotDetail = async () => {
      try {
        const response = await getRobotDetail(robotId);
        if (response.data) {
          setRobotDetail(response.data);
        } else {
          setRobotDetail(null);
        }
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching drone detail:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRobotDetail();
  }, [robotId]);

  return (
    <div
      className="container mt-5 d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div className="col-md-10 col-lg-8">
        <h2 className="text-center mb-4" style={{ fontSize: "2rem" }}>
          Robot Detail
        </h2>
        {loading ? (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Loading...
          </p>
        ) : robotDetail ? (
          <div
            className="card shadow-lg hover-card"
            style={{
              padding: "2rem",
              fontSize: "1.5rem",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <div className="card-body">
              <h5
                className="card-title mb-4 text-center"
                style={{ fontSize: "2rem" }}
              >
                Robot ID: {robotDetail.id}
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Type:</strong> {robotDetail.typeRobot}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Model: </strong>
                    {robotDetail.model}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Status: </strong>{" "}
                    {robotDetail.status ? (
                      <p className="text-success">Online</p>
                    ) : (
                      <p className="text-danger">Offline</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Time Remain: </strong> {robotDetail.time}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-text mb-3">
                    <strong>Energy Remain: </strong> {robotDetail.energy}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center" style={{ fontSize: "1.25rem" }}>
            Robot detail not found.
          </p>
        )}
      </div>
    </div>
  );
};

export default RobotDetails;
