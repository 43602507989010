import Http from "./Http";
import { getToken } from "../shared/ultils/token";

// Đăng nhập người dùng
export const loginUser = (username, password) => {
  return Http.post(`${process.env.REACT_APP_API_LOGIN}`, {
    username,
    password,
  });
};

// Lấy danh sách tất cả người dùng
export const getAllUsers = () => {
  const token = getToken();
  return Http.get(`${process.env.REACT_APP_API_GET_ALL_USERS}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Thêm token vào header Authorization
    },
  });
};

// Lấy thông tin người dùng theo ID
export const getUserInfoById = (id) => {
  const token = getToken();
  return Http.get(`${process.env.REACT_APP_API_GET_USER_INFO_BY_ID}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Thêm token vào header Authorization
    },
  });
};

// Chỉnh sửa thông tin người dùng
export const editUserInfo = (id, userInfo) => {
  const token = getToken();
  return Http.put(
    `${process.env.REACT_APP_API_EDIT_USER_INFO}/${id}`,
    userInfo,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Thêm token vào header Authorization
      },
    }
  );
};

// Tạo tài khoản admin mới
export const createAdmin = (adminData) => {
  const token = getToken();
  return Http.post(`${process.env.REACT_APP_API_CREATE_ADMIN}`, adminData, {
    headers: {
      Authorization: `Bearer ${token}`, // Thêm token vào header Authorization
    },
  });
};

// Lấy danh sách người dùng
export const getUserList = () => {
  const token = getToken();
  return Http.get(`${process.env.REACT_APP_API_GET_USER_LIST}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Thêm token vào header Authorization
    },
  });
};
