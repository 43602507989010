import React, { useState } from "react"; // Import React và useState
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux-setup/store";
import Header from "./shared/components/Header";
import Sidebar from "./shared/components/Sidebar";
import AddUser from "./Users/AddUser";
import OverView from "./Users/OverView";
import Profile from "./UserProfile/Profile";
import Products from "./Ecommerce/Products/Products";
import Settings from "./Account/Settings";
import ProductDetails from "./Ecommerce/Products/ProductDetails";
import AddProduct from "./Ecommerce/Products/AddProduct";
import Orders from "./Ecommerce/Orders/Orders";
import OrderDetails from "./Ecommerce/Orders/OrderDetails";
import ManageReviews from "./Ecommerce/ManageReviews";
import Checkout from "./Ecommerce/Checkout";
import NotFound404 from "./Ecommerce/NotFound404";
import Customers from "./Ecommerce/Customers/Customers";
import CustomerDetail from "./Ecommerce/Customers/CustomerDetail";
import AddCustomers from "./Ecommerce/Customers/AddCustomers";
import Signin from "./Authentication/Signin";
import Signup from "./Authentication/Signup";
import ForgetPassword from "./Authentication/ForgetPassword";
import EmailVerification from "./Authentication/EmailVerification";
import VerificationStep2 from "./Authentication/VerificationStep2";
import CountryList from "../src/Areas/CountryList";
import FieldList from "../src/Areas/FieldList";
import RegionList from "../src/Areas/RegionList";
import RubberTreeList from "./Areas/RubberTreeList";
import RubberTreeDetails from "./Areas/RubberTreeDetails";
import PlanList from "./Plans/PlanList";
import PlanDetail from "./Plans/PlanDetail";
import TaskList from "./Tasks/TaskList";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminUserInfo from "./Admin/AdminUserInfo";
import AdminEditUserInfo from "./Admin/AdminEditUserInfo";
import DroneList from "./Drones/DroneList";
import DroneDetail from "./Drones/DroneDetail";
import SensorControlSystemDetail from "./Drones/SensorControlSystemDetail";
import RobotList from "./Robot/RobotList";
import RobotDetails from "./Robot/RobotDetails";
import RobotTappingList from "./Robot/RobotTappingList";
import RobotTappingDetail from "./Robot/RobotTappingDetail";
import "./App.css";

const App = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Trạng thái navbar

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen); // Đảo ngược trạng thái navbar
  };

  //create State (isNavbarOpen) to show or hide navbar
  //if isNavbarOpen=> sidebar is open -> show sidebar, set padding left of content and header = sidebar width + 2rem
  //if isNavbarOpen === false -> sidebar is closed, hide sidebar, set padding left of content and header = 2rem, show button to open sidebar
  // *** note : sidebar and header are wrong names ***
  const location = useLocation();

  const noHeaderFooter = [
    process.env.REACT_APP_ROUTE_SIGNIN,
    process.env.REACT_APP_ROUTE_SIGNUP,
    process.env.REACT_APP_ROUTE_FORGETPASSWORD,
    process.env.REACT_APP_ROUTE_EMAILVERIFICATION,
    process.env.REACT_APP_ROUTE_VERIFICATIONSTEP2,
  ];

  return (
    <div className={`main-content ${isNavbarOpen ? "" : "full-screen"}`}>
      {/* Header và Sidebar chỉ hiển thị nếu không phải các tuyến đường không cần header/footer */}
      {!noHeaderFooter.includes(location.pathname) && isNavbarOpen && (
        <Header isNavbarOpen onToggle={toggleNavbar} />
      )}
      {!noHeaderFooter.includes(location.pathname) && (
        <Sidebar isNavbarOpen={isNavbarOpen} onToggle={toggleNavbar} />
      )}

      <div
        style={{
          padding: "2rem",
          paddingLeft: isNavbarOpen ? "282px" : "2rem",
        }}
        className={`content ${isNavbarOpen ? "" : "expanded"}`}
      >
        {/* Khi sidebar tắt, content sẽ chiếm toàn bộ chiều rộng */}
        <Routes>
          <Route
            path={process.env.REACT_APP_ROUTE_OVERVIEW}
            element={<OverView />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADDUSER}
            element={<AddUser />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_PROFILE}
            element={<Profile />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_SETTINGS}
            element={<Settings />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_PRODUCTS}
            element={<Products />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_PRODUCTDETAILS}
            element={<ProductDetails />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADDPRODUCT}
            element={<AddProduct />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ORDERS}
            element={<Orders />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ORDERDETAILS}
            element={<OrderDetails />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_CUSTOMERS}
            element={<Customers />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_CUSTOMERDETAIL}
            element={<CustomerDetail />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADDCUSTOMERS}
            element={<AddCustomers />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_MANAGEREVIEWS}
            element={<ManageReviews />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_CHECKOUT}
            element={<Checkout />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_SIGNIN}
            element={<Signin />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_SIGNUP}
            element={<Signup />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_FORGETPASSWORD}
            element={<ForgetPassword />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_EMAILVERIFICATION}
            element={<EmailVerification />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_VERIFICATIONSTEP2}
            element={<VerificationStep2 />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_COUNTRY}
            element={<CountryList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_REGION}
            element={<RegionList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_FIELD}
            element={<FieldList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_RUBBERTREE}
            element={<RubberTreeList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_RUBBERTREEDETAILS}
            element={<RubberTreeDetails />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_PLAN}
            element={<PlanList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_PLANDDETAIL}
            element={<PlanDetail />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_TASKPLAN}
            element={<TaskList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_TASKROBOT}
            element={<TaskList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_TASK}
            element={<TaskList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADMINDASHBOARD}
            element={<AdminDashboard />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADMINUSERINFO}
            element={<AdminUserInfo />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ADMINEDITUSERINFO}
            element={<AdminEditUserInfo />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_DRONE}
            element={<DroneList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_DRONEDETAIL}
            element={<DroneDetail />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_SCS}
            element={<SensorControlSystemDetail />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ROBOT}
            element={<RobotList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ROBOTDETAIL}
            element={<RobotDetails />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ROBOTTAPPING}
            element={<RobotTappingList />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_ROBOTTAPPINGDETAIL}
            element={<RobotTappingDetail />}
          />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </div>
    </div>
  );
};
// Wrapper cho App với BrowserRouter
function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
